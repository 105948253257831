import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk"; // Correct import
import { composeWithDevTools } from "redux-devtools-extension";
import authReducer from "../store/sample/reducer/authReducer"; // Example reducer

const rootReducer = combineReducers({
  auth: authReducer, // Add other reducers here
  // otherReducer: otherReducer
});

const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(thunk))
);

export default store;
