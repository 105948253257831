import React, { useState, useEffect } from "react";
import { Container, Card, Col, Row, Form, Dropdown } from "react-bootstrap";
import {
  TextField,
  Divider,
  Typography,
  Grid,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import "../../scss/MovieUploadComponent.scss";
import { FormControlLabel, Switch } from "@mui/material";
import { styled } from "@mui/material/styles";
import { green } from "@mui/material/colors";
import ScheduleUploadCard from "./ScheduleUploadCard";
import ImageUpload from "../../common/FileUploads/ImageUpload";
import VideoUpload from "../../common/FileUploads/VideoUpload";
import SongVideoUpload from "../../common/FileUploads/SongVideoUpload";
import {
  getcertificatelist,
  getGenreList,
  getlanguageList,
  movieCreateApi,
} from "../../services/movieupload";
import buttonicon from "../../images/UploadMoviePage/ButtonIcon.svg";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
   

const CustomDropdown = ({ title, options, onChange }) => {
  const [selectedOption, setSelectedOption] = React.useState(title);

  const handleSelect = (eventKey) => {
    const selectedOption = options.find((option) => option.name === eventKey);
    setSelectedOption(selectedOption.name);
    onChange(selectedOption.id, selectedOption.name);
  };

  return (
    <Dropdown onSelect={handleSelect} style={{ width: "100%" }}>
      <Dropdown.Toggle
        variant="dark"
        id="dropdown-basic"
        style={{
          width: "100%",
          height: "60px",
          backgroundColor: "black",
          color: "white",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
        className="custom-dropdown-toggle"
      >
        {selectedOption}
        <span className="dropdown-arrow" style={{ marginLeft: "auto" }}>
          &#x25BC;
        </span>
      </Dropdown.Toggle>

      <Dropdown.Menu
        style={{
          width: "100%",
          backgroundColor: "black",
          color: "white",
          maxHeight: "300px",
          overflowY: "auto",
        }}
      >
        {options?.map((option) => (
          <Dropdown.Item
            key={option.id}
            eventKey={option.name}
            style={{
              backgroundColor:
                selectedOption === option.name ? "grey" : "black",
              color: "white",
            }}
          >
            {option.name}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

const CustomForm = ({
  onChange,
  listgenre,
  listofCeriified,
  listofLangugae,
  formState,
}) => {
  return (
    <Container fluid>
      <Form>
        <Row className="mb-3">
          <Col md={6}>
            <Form.Group controlId="genre">
              <Form.Label style={{ color: "white" }}>Genre</Form.Label>
              <CustomDropdown
                options={listgenre}
                onChange={(id, label) => onChange("genre_id", id)}
              />
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group controlId="language">
              <Form.Label style={{ color: "white" }}>Language</Form.Label>
              <CustomDropdown
                options={listofLangugae}
                onChange={(id, label) => onChange("language_id", id)}
              />
            </Form.Group>
          </Col>
        </Row>

        <Row className="mb-3">
          <Col md={6}>
            <Form.Group controlId="movieCertificate">
              <Form.Label style={{ color: "white" }}>
                Movie Certificate
              </Form.Label>
              <CustomDropdown
                options={listofCeriified}
                onChange={(id, label) => onChange("certificate_id", id)}
              />
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group controlId="madeForKids">
              <Form.Label style={{ color: "white" }}>Made for Kids</Form.Label>
              <CustomDropdown
                options={[
                  { id: true, name: "Yes" },
                  { id: false, name: "No" },
                ]}
                onChange={(id, label) => onChange("made_for_kids", id)}
              />
            </Form.Group>
          </Col>
        </Row>
      </Form>
    </Container>
  );
};

const CustomForm2 = ({ onChange, formState, errors }) => {
  return (
    <Container fluid>
      <Form>
        <Row className="mb-3">
          <Col md={6}>
            <Form.Group controlId="moviePlan">
              <Form.Label style={{ color: "white" }}>Movie Plan</Form.Label>
              <CustomDropdown
                options={[
                  { id: "free", name: "Free" },
                  { id: "first_show", name: "First Show" },
                  { id: "subscribed", name: "Subscribed" },
                ]}
                onChange={(id, label) => onChange("movie_plan", id)}
              />
            </Form.Group>
          </Col>
          {formState.movie_plan === "first_show" && (
            <Col md={6}>
              <Form.Group controlId="price">
                <Form.Label style={{ color: "white" }}>Price</Form.Label>
                <TextField
                  variant="outlined"
                  placeholder="Enter Price"
                  fullWidth
                  type="text"
                  value={formState.price}
                  disabled={formState.movie_plan !== "first_show"}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (/^\d*\.?\d*$/.test(value)) {
                      onChange("price", value);
                    }
                  }}
                  InputProps={{
                    style: { color: "#fff", backgroundColor: "#161616" },
                    inputProps: { pattern: "[0-9]*[.]?[0-9]*" },
                  }}
                  style={{ marginBottom: "20px" }}
                  error={!!errors.price}
                  helperText={errors.price}
                />
              </Form.Group>
            </Col>
          )}
        </Row>
      </Form>
    </Container>
  );
};

const IOSSwitch = styled(Switch)(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 1,
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.primary.main,
        opacity: 1,
      },
    },
    "&.Mui-checked + .MuiSwitch-track": {
      backgroundColor: theme.palette.primary.main,
      opacity: 1,
    },
  },
  "& .MuiSwitch-thumb": {
    width: 24,
    height: 24,
    borderRadius: 12,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: green,
    opacity: 1,
  },
}));

const MovieUploadComponent = () => {
  const navigate = useNavigate();
  const [listofLangugae, setlistofLangugae] = useState([]);
  const [listgenre, setlistgenre] = useState([]);
  const [listofCeriified, setlistofCeriified] = useState([]);
  const [Loading, setLoading] = useState(false);
  const [maxLength, setmaxLength] = useState(120);
  const [formState, setFormState] = useState({
    title: "",
    description: null,
    thumbnail: "",
    cover_pic: "",
    vimeo_video_id: "",
    genre_id: "",
    made_for_kids: true,
    language_id: "",
    certificate_id: "",
    certificate_url: "",
    movie_plan: "",
    price: "",
    move_into_subscription: false,
    director: "",
    producer_1: "",
    producer_2: "",
    cast_1: "",
    cast_2: "",
    cast_3: "",
    schedule_date: "",
    schedule_time: "",
    vimeo_trailer_id: "",
    trailer_thumbnail: "",
    songs: [
      { title: "", thumbnail: "", vimeo_video_id: "" },
      { title: "", thumbnail: "", vimeo_video_id: "" },
      { title: "", thumbnail: "", vimeo_video_id: "" },
    ],
  });

  const [errors, setErrors] = useState({});

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      event.returnValue = "Your changes will be lost if you leave this page.";
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  const handleUpload = (key, imageUrl) => {
    setFormState((prevState) => ({ ...prevState, [key]: imageUrl }));
  };

  const handleInputChange = (key, value) => {
    if (key === "description") {
      const arrayOfWords = value.split(" ")?.filter((word) => word.length > 0);

      setmaxLength(arrayOfWords.length);
      if (arrayOfWords?.length <= 120) {
        setFormState((prevState) => ({ ...prevState, [key]: value }));
        // setErrors({ description: "" });
      } else if (arrayOfWords?.length > 120) {
        setErrors({ description: `Maximum 120 words allowed.` });
      }
    } else {
      setFormState((prevState) => ({ ...prevState, [key]: value }));
    }
  };

  const handleSongChange = (index, key, value) => {
    const updatedSongs = [...formState.songs];
    updatedSongs[index][key] = value;
    setFormState((prevState) => ({ ...prevState, songs: updatedSongs }));
  };

  const validateForm = () => {
    const {
      title,
      description,
      thumbnail,
      cover_pic,
      vimeo_video_id,
      genre_id,
      made_for_kids,
      movie_plan,
    } = formState;

    let newErrors = {};

    // if (!title || !/^[a-zA-Z0-9\s!@#$%^&*()_+=-]*$/.test(title)) {
    //   newErrors.title = "Title is required and must be alphanumeric.";
    // }
    if (!title || !/^[\w\s!@#$%^&*()_+=\[\]{};:'"\\|,.<>?`~-]*$/.test(title)) {
      newErrors.title = "Title is required.";
    }
    // if (!description || !/^[a-zA-Z0-9\s!@#$%^&*()_+=-]*$/.test(description)) {
    //   newErrors.description =
    //     "Description is required and must be alphanumeric.";
    // }
    if (
      !description ||
      !/^[\w\s!@#$%^&*()_+=\[\]{};:'"\\|,.<>?`~-]*$/.test(description)
    ) {
      newErrors.description = "Description is required.";
    }
    if (!thumbnail) {
      newErrors.thumbnail = "Thumbnail is required.";
    }
    if (!cover_pic) {
      newErrors.cover_pic = "Cover photo is required.";
    }
    if (!vimeo_video_id) {
      newErrors.vimeo_video_id = "Movie upload is required.";
    }
    if (!genre_id) {
      newErrors.genre_id = "Genre is required.";
    }
    if (made_for_kids === "") {
      newErrors.made_for_kids = "Made for Kids field is required.";
    }
    if (movie_plan === "") {
      newErrors.movie_plan = "Movie Plan is required.";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleFormSubmit = async () => {
    if (!validateForm()) {
      toast.error("Please fill all the required fields");
      window.scrollTo(0, 0);
      return;
    }

    try {
      setLoading(true);
      const payload = { ...formState };
      const response = await movieCreateApi(payload);
      if (response?.data?.status === true) {
        toast.success("Movie created successfully");
        navigate(`/mainpage/${5}`);
        setLoading(false);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
      setLoading(false);
    } finally {
      setLoading(false);
    }
    setLoading(false);
  };

  const Fetchlanguagelist = async () => {
    try {
      const response = await getlanguageList();
      if (response?.data?.status) {
        setlistofLangugae(response?.data?.data?.languages);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  const FetchGenre = async () => {
    try {
      const response = await getGenreList();
      if (response?.data?.status) {
        setlistgenre(response?.data?.data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  const FetchlistofCertifi = async () => {
    try {
      const response = await getcertificatelist();
      if (response?.data?.status) {
        setlistofCeriified(response?.data?.data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setLoading(true);
    FetchGenre();
    Fetchlanguagelist();
    FetchlistofCertifi();
  }, []);
  return (
    <>
      <Container fluid className="container-fluid" Loading={Loading}>
        <Card className="card">
          <Typography
            variant="h4"
            component="h1"
            style={{ marginBottom: "10px" }}
          >
            Upload New Movie
          </Typography>
          <Typography variant="subtitle1" component="p">
            Add new movie to your channel
          </Typography>
        </Card>

        <Divider style={{ backgroundColor: "white", margin: "20px 0" }} />

        <Card className="card movie-info-card">
          <Typography variant="h5" component="h2">
            Movie Information
          </Typography>
          <Divider style={{ backgroundColor: "white", margin: "20px 0" }} />
          <Typography
            variant="h6"
            component="h3"
            style={{ marginBottom: "10px" }}
          >
            Upload Movie Here
          </Typography>
          <VideoUpload
            onUpload={(videoUrl) => handleUpload("vimeo_video_id", videoUrl)}
            uploadedUrl={formState.vimeo_video_id}
          />

          <Typography
            variant="subtitle1"
            component="h4"
            style={{ marginBottom: "10px" }}
          >
            Title (Required)
            <TextField
              style={{
                fontFamily: "sans-serif",
                color: "rgba(255, 255, 255, 0.6)",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
              }}
              variant="outlined"
              placeholder="Enter video title..."
              fullWidth
              className="text-field"
              value={formState.title}
              onChange={(e) => handleInputChange("title", e.target.value)}
              // error={!!errors.title}
              helperText={errors.title}
            />
            {errors.title && (
              <Typography color="error" variant="caption">
                {errors.title}
              </Typography>
            )}
          </Typography>
          <Typography
            variant="subtitle1"
            component="h4"
            style={{
              marginBottom: "10px",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            Description (Required)
          </Typography>
          <TextField
            variant="outlined"
            placeholder="Enter video description..."
            fullWidth
            multiline
            rows={4}
            // inputProps={{ maxLength: 120 }}
            className="text-field"
            value={formState.description}
            onChange={(e) => handleInputChange("description", e.target.value)}
            helperText={errors.description}
          />
          <span> {maxLength}/120 </span>
          {errors.description && (
            <Typography color="error" variant="caption">
              {errors.description}
            </Typography>
          )}
          <Typography variant="h6" component="h3" mt={2}>
            Thumbnail (260px*400px){" "}
          </Typography>
          <Typography
            variant="p"
            className="para"
            style={{
              fontFamily: "sans-serif",
              color: "rgba(255, 255, 255, 0.6)",
              fontSize: "16px",
              fontWeight: "400",
              lineHeight: "24px",
            }}
          >
            Select or upload a picture that shows what's in your video. A good
            thumbnail stands out and draws viewers' attention.
          </Typography>
          <Col md={3}>
            <ImageUpload
              onUpload={(imageUrl) => handleUpload("thumbnail", imageUrl)}
              uploadedUrl={formState.thumbnail}
            />
            {errors.thumbnail && (
              <Typography color="error" variant="caption">
                {errors.thumbnail}
              </Typography>
            )}
          </Col>

          <Typography
            variant="h6"
            component="h3"
            style={{ marginBottom: "10px" }}
          >
            Cover Photo (1440px*540px){" "}
          </Typography>
          <Typography
            variant="p"
            className="para"
            style={{
              fontFamily: "sans-serif",
              color: "rgba(255, 255, 255, 0.6)",
              fontSize: "16px",
              fontWeight: "400",
              lineHeight: "24px",
            }}
          >
            Select or upload a picture that shows what's in your video. A good
            cover photo stands out and draws viewers' attention.
          </Typography>
          <ImageUpload
            onUpload={(imageUrl) => handleUpload("cover_pic", imageUrl)}
            uploadedUrl={formState.cover_pic}
          />
          {errors.cover_pic && (
            <Typography color="error" variant="caption">
              {errors.cover_pic}
            </Typography>
          )}

          <Typography
            variant="h6"
            component="h3"
            style={{ marginBottom: "10px" }}
          >
            Upload Trailer Here
          </Typography>
          <Typography
            variant="p"
            className="para"
            style={{
              fontFamily: "sans-serif",
              color: "rgba(255, 255, 255, 0.6)",
              fontSize: "16px",
              fontWeight: "400",
              lineHeight: "24px",
            }}
          >
            Select or drag and drop trailer from your device.
          </Typography>
          <VideoUpload
            onUpload={(videoUrl) => handleUpload("vimeo_trailer_id", videoUrl)}
            uploadedUrl={formState.vimeo_trailer_id}
          />

          <CustomForm
            onChange={handleInputChange}
            listgenre={listgenre}
            listofCeriified={listofCeriified}
            listofLangugae={listofLangugae}
            formState={formState}
          />
        </Card>

        <Card className="card movie-info-card">
          <Typography variant="h5" component="h2">
            Pricing Info
          </Typography>
          <Divider style={{ backgroundColor: "white", margin: "20px 0" }} />
          <CustomForm2
            onChange={handleInputChange}
            formState={formState}
            errors={errors}
          />
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginLeft: "1rem",
            }}
          >
            <FormControlLabel
              control={
                <IOSSwitch
                  sx={{ m: 1 }}
                  checked={formState.move_into_subscription}
                  onChange={(e) =>
                    handleInputChange(
                      "move_into_subscription",
                      e.target.checked
                    )
                  }
                />
              }
            />
            <span>
              Move this movie into Subscription after 5 week of publishing
            </span>
          </div>
        </Card>

        <Card className="card movie-info-card">
          <Typography variant="h5" component="h2">
            Song Upload
          </Typography>
          <Typography
            variant="body1"
            className="para"
            style={{
              fontFamily: "sans-serif",
              color: "rgba(255, 255, 255, 0.6)",
              fontSize: "16px",
              fontWeight: "400",
              lineHeight: "24px",
            }}
          >
            Select or upload music video and thumbnail here
          </Typography>
          <Divider style={{ backgroundColor: "white", margin: "20px 0" }} />
          <Row>
            {formState.songs.map((song, index) => (
              <Col md={4} key={index} style={{ marginBottom: "20px" }}>
                <Typography variant="h6" component="h3" mt={2}>{`Song ${
                  index + 1
                }`}</Typography>
                <Typography variant="para" mt={1}>
                  Title{" "}
                </Typography>

                <TextField
                  variant="outlined"
                  placeholder="Enter song title..."
                  fullWidth
                  value={song.title}
                  onChange={(e) =>
                    handleSongChange(index, "title", e.target.value)
                  }
                  InputProps={{
                    style: { color: "#fff", backgroundColor: "#161616" },
                  }}
                  style={{ marginBottom: "20px" }}
                  error={!!errors[`song_title_${index}`]}
                  helperText={errors[`song_title_${index}`]}
                />
                <SongVideoUpload
                  onUpload={(videoUrl) =>
                    handleSongChange(index, "vimeo_video_id", videoUrl)
                  }
                  uploadedUrl={song.vimeo_video_id}
                />
                <Typography className="para" sx={{ mt: 3, mb: 0 }}>
                  Upload Thumbnail Image (400px*200px)
                </Typography>
                <ImageUpload
                  onUpload={(imageUrl) =>
                    handleSongChange(index, "thumbnail", imageUrl)
                  }
                  uploadedUrl={song.thumbnail}
                />
              </Col>
            ))}
          </Row>
        </Card>

        <Card className="card movie-info-card">
          <Typography variant="h5" component="h2">
            Cast and Crew
          </Typography>
          <Divider style={{ backgroundColor: "white", margin: "20px 0" }} />

          <Typography variant="subtitle1">Director</Typography>
          <TextField
            variant="outlined"
            placeholder="Director Name"
            fullWidth
            value={formState.director}
            onChange={(e) => handleInputChange("director", e.target.value)}
            InputProps={{
              style: { color: "#fff", backgroundColor: "#161616" },
            }}
            style={{ marginBottom: "20px" }}
            error={!!errors.director}
            helperText={errors.director}
          />

          <Grid container spacing={2} style={{ marginBottom: "20px" }}>
            <Grid item xs={6}>
              <Typography variant="subtitle1">Producer 1</Typography>
              <TextField
                variant="outlined"
                placeholder="Producer Name"
                fullWidth
                value={formState.producer_1}
                onChange={(e) =>
                  handleInputChange("producer_1", e.target.value)
                }
                InputProps={{
                  style: { color: "#fff", backgroundColor: "#161616" },
                }}
                error={!!errors.producer_1}
                helperText={errors.producer_1}
              />
            </Grid>
            <Grid item xs={6}>
              <Typography variant="subtitle1">Producer 2</Typography>
              <TextField
                variant="outlined"
                placeholder="Producer Name"
                fullWidth
                value={formState.producer_2}
                onChange={(e) =>
                  handleInputChange("producer_2", e.target.value)
                }
                InputProps={{
                  style: { color: "#fff", backgroundColor: "#161616" },
                }}
                error={!!errors.producer_2}
                helperText={errors.producer_2}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Typography variant="subtitle1">Cast 1</Typography>
              <TextField
                variant="outlined"
                placeholder="Cast Name"
                fullWidth
                value={formState.cast_1}
                onChange={(e) => handleInputChange("cast_1", e.target.value)}
                InputProps={{
                  style: { color: "#fff", backgroundColor: "#161616" },
                }}
                error={!!errors.cast_1}
                helperText={errors.cast_1}
              />
            </Grid>
            <Grid item xs={4}>
              <Typography variant="subtitle1">Cast 2</Typography>
              <TextField
                variant="outlined"
                placeholder="Cast Name"
                fullWidth
                value={formState.cast_2}
                onChange={(e) => handleInputChange("cast_2", e.target.value)}
                InputProps={{
                  style: { color: "#fff", backgroundColor: "#161616" },
                }}
                error={!!errors.cast_2}
                helperText={errors.cast_2}
              />
            </Grid>
            <Grid item xs={4}>
              <Typography variant="subtitle1">Cast 3</Typography>
              <TextField
                variant="outlined"
                placeholder="Cast Name"
                fullWidth
                value={formState.cast_3}
                onChange={(e) => handleInputChange("cast_3", e.target.value)}
                InputProps={{
                  style: { color: "#fff", backgroundColor: "#161616" },
                }}
                error={!!errors.cast_3}
                helperText={errors.cast_3}
              />
            </Grid>
          </Grid>
        </Card>

        <Card className="card movie-info-card">
          <Typography variant="h5" component="h2">
            Schedule Upload
          </Typography>
          <Divider style={{ backgroundColor: "white", margin: "20px 0" }} />
          <ScheduleUploadCard onChange={handleInputChange} />
        </Card>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
            padding: "30px",
          }}
        >
          <button
            style={{
              display: "flex",
              alignItems: "center",
              padding: "10px 20px",
              borderRadius: "30px",
              border: "none",
              backgroundColor: "#FED530",
              cursor: "pointer",
            }}
            onClick={handleFormSubmit}
          >
            <img
              src={buttonicon}
              alt="Button Icon"
              style={{ marginRight: "10px", width: "22px", height: "18px" }}
            />
            Upload Movie
          </button>
        </div>
      </Container>
      <Backdrop
        open={Loading}
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default MovieUploadComponent;
