// src/components/MembershipPlans.jsx
import React, { useCallback, useEffect, useState } from "react";
import { Box, Typography, Button, Divider } from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import "../../scss/MembershipPlans.scss";
import icon from "../../images/AccountPage/subscribe_icon.svg";
import {
  choosplan,
  createorderforsubscription,
  getsubscriptionList,
} from "../../../src/services/home";
import useRazorpay from "react-razorpay";
import { Check } from "@mui/icons-material";

const MembershipPlans = () => {
  const [listData, setlistData] = useState([]);
  const [isDoingPayement, setIsDoingPayement] = useState(false);
  const [usersubscription, setusersubscription] = useState(null);
  const userDataString = localStorage.getItem("userData");
  const userData = JSON.parse(userDataString);
  const [Razorpay, isLoaded] = useRazorpay();
  const [loading, setLoading] = useState(false);

  const handlePayment = useCallback(
    async (plan) => {
      let order = null;
      const response = await createorderforsubscription({
        subscription_id: plan?.id,
      });
      if (response?.data?.status) {
        order = response?.data?.data?.order;
        try {
          const options = {
            amount: order.amount,
            key: "rzp_test_VneipD1TZu0Xav",
            currency: order?.currency,
            name: userData?.name,
            description: "",
            image: "",
            order_id: order.id,
            handler: (res) => {
              subscribe(res, plan);
            },
            prefill: {
              name: userData?.name,
              email: userData?.email,
              contact: userData?.mobile,
            },
            notes: {
              address: "Zerozilla Corporate Office",
            },
            theme: {
              color: "#3399cc",
            },
          };
          const rzpay = new Razorpay(options);
          setLoading(true);
          rzpay.open();
        } catch (error) {
          console.error("Payment error:", error);
          setLoading(false);
        }
      }

      if (!isLoaded) return;
      setLoading(false);
      setIsDoingPayement(false);
    },
    [isLoaded, Razorpay]
  );
  useEffect(() => {}, [handlePayment]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getsubscriptionList();
        if (response?.status === 200) {
          setlistData(response?.data?.data?.subscriptions);
          setusersubscription(
            response?.data?.data?.user_subscription?.Subscription || null
          );
        }
      } catch (error) {
        console.error("Error fetching subscription list:", error);
      }
    };

    fetchData();
  }, []);

  const subscribe = async (data, plan) => {
    const payload = {
      subscription_id: plan?.id,
      razorpay_payment_id: data?.razorpay_payment_id,
      razorpay_order_id: data?.razorpay_order_id,
      razorpay_signature: data?.razorpay_signature,
    };
    const response = await choosplan(payload);
    if (response?.status === 200) {
      const response = await getsubscriptionList();
      if (response?.status === 200) {
        setlistData(response?.data?.data?.subscriptions);
        setusersubscription(
          response?.data?.data?.user_subscription?.Subscription || null
        );
      }
    }
  };
  return (
    <Box className="membership-plans">
      <Typography variant="h5" component="div" gutterBottom>
        Membership Plans
      </Typography>

      {usersubscription !== null && (
        <>
          <Typography variant="subtitle1" component="div" gutterBottom>
            Choose and Manage Your Membership Plan
          </Typography>
          <Box className="current-plan">
            <div
              style={{
                border: "1px solid black",
                width: "80px",
                height: "80px",
                borderRadius: "50px",
                backgroundColor: "black",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                src={icon}
                alt=""
                style={{ width: "33px", height: "27px" }}
              />
            </div>
            <Box className="plan-info">
              <Typography variant="body2" className="plan-label">
                Current Plan
              </Typography>
              <Typography variant="subtitle1" className="plan-title">
                {usersubscription?.title}
              </Typography>
            </Box>
          </Box>
        </>
      )}
      <Box className="MembershipPlans-sub_heading">
        <Typography variant="h6" component="div" gutterBottom>
          Available Membership Plans
        </Typography>
      </Box>
      <Box className="available-plans">
        {listData.map((plan, index) => (
          <Box key={index} className="plan-card">
            <Box style={{ display: "flex", justifyContent: "space-between" }}>
              <img
                src={icon}
                alt=""
                style={{ width: "44px", height: "36px" }}
              />
              <Typography variant="body2" className="plan-price">
                {plan.price} / {plan?.duration_type}
              </Typography>
            </Box>
            <Divider className="divider" />
            <Typography variant="h6" className="plan-title">
              {plan.title}
            </Typography>
            <Typography variant="body2" className="plan-description">
              {plan.description}
            </Typography>
            <Button
              variant="contained"
              className={`plan-button ${plan?.subscribed ? "current" : ""}`}
              endIcon={plan?.subscribed ? <Check /> : <ArrowForwardIosIcon />}
              disabled={loading || plan?.subscribed || isDoingPayement}
              onClick={() => {
                setIsDoingPayement(true);
                handlePayment(plan);
              }}
            >
              {plan?.subscribed ? "Current Plan" : "Choose Plan"}
            </Button>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default MembershipPlans;
