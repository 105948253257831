// src/App.js
import React, { Suspense } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  // useLocation,
} from "react-router-dom";
// import Introductonvideo from "./components/About/Introductonvideo";
import Welcomepage from "./components/WelcomePage/Welcomepage";
import Login from "./components/login";
// import Navbar from "./common/navbar/navbar";
import HomePage from "./components/HomePage/HomePage";
import Otp from "./components/Otp/OtpPage";
import AccountPage from "./components/Account/AccountPage";
import LanguagePage from "./components/Language/LanguagePage";
import MoreDetails from "./components/MoreDetails/MoreDetails";
import PersonalInformation from "./components/personalInfo/PersonalInformation";
import ProtectedRoute from "./routes/privateRoutes";
import { AuthProvider } from "./routes/AuthContext";
import { GoogleOAuthProvider } from "@react-oauth/google";
import Account from "./components/accountPage/Account";
import MovieUploadComponent from "./components/movieUploadComponent/MovieUploadComponent";
import UnauthorizedPage from "./components/UnderConstruction/UnauthorizedPage";
import MovieAnalyticsPage from "./components/MovieAnalytic/MovieAnalyticsPage";
import { Toaster } from "react-hot-toast";
import Contact from "./components/ContactUs/Contact";
import FirstShowMovies from "./components/Movies/FirstShowMovies";
import SubscribeMovies from "./components/Movies/SubscribeMovies";
import WatchListMovies from "./components/Movies/WatchListMovies";
import WatchMovie from "./components/Movies/WatchMovie";
// import UnProtectedRoute from "./routes/UnProtectedRoute";
import PrivacyPolicy from "./components/PrivacyPolicy/PrivacyPolicy";
import TermsAndCondition from "./components/Terms&Condition/TermsAndCondition";
import CategorisedMovies from "./components/Movies/CategorisedMovies";
// import Navbarforlogin from "./common/navbar/Navbarforlogin";
import Layout from "./Layout";
import NotFound from "./components/UnderConstruction/NotFound";
import ProducerDetails from "./components/Account/ProducerDetails";
import ShareMoredetals from "./components/MoreDetails/shareMoredetals";
import Aboutus from "./components/About/about-us";
import Faq from "./components/Faq/Faq";
import Screen1 from "./components/Screen/Screen1";
import ScreenHome from "./components/Screen/ScreenHome";

function App() {
  return (
    <GoogleOAuthProvider clientId="81510403200-m4dc9cacn8ccdctum9bpn474bqgdktf7.apps.googleusercontent.com">
      <AuthProvider>
        {" "}
        <Router>
          <Layout>
            <Suspense fallback={<div>Loading...</div>}>
              {/* <Navbar />
            <Navbarforlogin/> */}
              <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="/home" element={<HomePage />} />
                {/* <Route path="/" element={<Introductonvideo />} /> */}
                <Route path="/welcome" element={<Welcomepage />} />
                <Route path="/login" element={<Login />} />
                <Route path="/producer-details" element={<ProducerDetails />} />
                <Route path="privacy-policy" element={<PrivacyPolicy />} />
                <Route path="terms-condition" element={<TermsAndCondition />} />
                <Route path="/otp" element={<Otp tokenType={"otp_token"} />} />
                <Route path="/account" element={<AccountPage />} />
                <Route path="/info" element={<PersonalInformation />} />
                <Route path="/language" element={<LanguagePage />} />
                <Route path="/moredetails/:id" element={<MoreDetails />} />
                <Route path="/share/moredetails/:id" element={<ShareMoredetals />} />

                <Route path="/movie/streaming/:id" element={<WatchMovie />} />
                <Route
                  path="/mainpage/:type"
                  element={
                    <ProtectedRoute>
                      <Account />
                    </ProtectedRoute>
                  }
                />
                <Route path="/contact-us" element={<Contact />} />
                {/* <Route path="/about-us" element={<Aboutus />} /> */}
                {/* <Route path="/mainpage" element={<Account />} /> */}
                <Route path="/movieUpload" element={<MovieUploadComponent />} />
                <Route path="/unauthorized" element={<UnauthorizedPage />} />
                <Route
                  path="/analytic/:id"
                  element={
                    <ProtectedRoute>
                      <MovieAnalyticsPage />
                    </ProtectedRoute>
                  }
                />
                <Route path="/movies" element={<CategorisedMovies />} />
                <Route
                  path="/movies/first-show"
                  element={<FirstShowMovies />}
                />
                <Route path="/movies/subscribe" element={<SubscribeMovies />} />
                <Route
                  path="/movies/watch-list"
                  element={<WatchListMovies />}
                />
                <Route path="*" element={<NotFound />} />

                <Route path="/faq" element={<Faq />} />
                <Route path="/film-maker" element={<ScreenHome />} />
              </Routes>
            </Suspense>
          </Layout>
        </Router>
      </AuthProvider>
      <Toaster />
    </GoogleOAuthProvider>
  );
}

export default App;
