import React from "react";
import { Grid, Button, Typography, Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ContactImg from "../../images/Contact/contact_img.jpeg";
import "../../scss/Contact.scss";

const Faq = () => {
    return (
        <Grid container className="contact-body">
            <Grid container>
                <Grid
                    item
                    xs={12}
                    md={8}
                    sx={{
                        py: { xs: "1rem", md: 7 },
                        px: { xs: "1rem", md: 10 },
                    }}
                >
                    <Typography variant="para" className="contact-title">
                        Frequently Asked Questions
                    </Typography>
                    <br />
                    <Typography variant="para" className="contact-sub-title">
                        If you have any more questions or need further information, please feel free to <br /> contact us. We’re here to help.
                    </Typography>
                    <div className="mt-3 mb-4">
                        <Button
                            variant="contained"
                            className="faq-button"
                        >
                            Contact Us
                        </Button>
                    </div>
                    <hr />
                    <div sx={{ my: 5 }}>
                        <Accordion sx={{ mt: 2 }} className="faq_card">
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon className="faq-icon" />}
                                aria-controls="panel1-content"
                                id="panel1-header"
                                className="faq-card"
                            >
                                <Typography className="accordion_text1">What is special about CINET?</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography className="accordion_text2">
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
                                    malesuada lacus ex, sit amet blandit leo lobortis eget.
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion sx={{ mt: 2 }} className="faq_card">
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon className="faq-icon" />}
                                aria-controls="panel1-content"
                                id="panel1-header"
                                className="faq-card"
                            >
                                <Typography className="accordion_text1">Who decides the streaming schedule</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography className="accordion_text2">
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
                                    malesuada lacus ex, sit amet blandit leo lobortis eget.
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion sx={{ mt: 2 }} className="faq_card">
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon className="faq-icon" />}
                                aria-controls="panel1-content"
                                id="panel1-header"
                                className="faq-card"
                            >
                                <Typography className="accordion_text1">When will I receive my share of the revenue?</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography className="accordion_text2">
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
                                    malesuada lacus ex, sit amet blandit leo lobortis eget.
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion sx={{ mt: 2 }} className="faq_card">
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon className="faq-icon" />}
                                aria-controls="panel1-content"
                                id="panel1-header"
                                className="faq-card"
                            >
                                <Typography className="accordion_text1">What is the benefit of a ‘simultaneous release’?</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography className="accordion_text2">
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
                                    malesuada lacus ex, sit amet blandit leo lobortis eget.
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion sx={{ mt: 2 }} className="faq_card">
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon className="faq-icon" />}
                                aria-controls="panel1-content"
                                id="panel1-header"
                                className="faq-card"
                            >
                                <Typography className="accordion_text1">Who decides the ticket price?</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography className="accordion_text2">
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
                                    malesuada lacus ex, sit amet blandit leo lobortis eget.
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion sx={{ mt: 2 }} className="faq_card">
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon className="faq-icon" />}
                                aria-controls="panel1-content"
                                id="panel1-header"
                                className="faq-card"
                            >
                                <Typography className="accordion_text1">Do you show only selective languages of movies?</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography className="accordion_text2">
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
                                    malesuada lacus ex, sit amet blandit leo lobortis eget.
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                    </div>

                </Grid>
                <Grid
                    item
                    xs={12}
                    md={4}
                    sx={{
                        display: { xs: "none", md: "block" },
                    }}
                >
                    <div
                        style={{
                            position: "relative",
                            width: "100%",
                            height: "100%",
                            overflow: "hidden",
                        }}
                    >
                        <img
                            src={ContactImg}
                            alt="Contact"
                            style={{
                                width: "100%",
                                height: "100%",
                                objectFit: "cover",
                                position: "absolute",
                                top: 0,
                                left: 0,
                            }}
                        />
                        <div
                            style={{
                                position: "absolute",
                                top: 0,
                                left: 0,
                                width: "100%",
                                height: "100%",
                                background:
                                    "linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, #101010 89.65%)",
                            }}
                        />
                    </div>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default Faq