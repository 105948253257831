import React, { useEffect, useState } from "react";
import HeaderCarousel from "../HomePage/HeaderCarousel";
import SliderCarousel from "../HomePage/SlidersCarousel";
import "../../scss/CrossfadeCarousel.scss";
   
import { firstShowMovieList, toggleWishlist } from "../../services/home";
import Spinner from "react-bootstrap/Spinner";
import { useLocation } from "react-router-dom";
import MoviesConstruction from "../UnderConstruction/MoviesConstruction";
import { Toaster, toast } from "react-hot-toast";

export default function FirstShowMovies() {
  const location = useLocation();
  const { languageId } = location.state || {};
  const [value, setValue] = useState({});
  const [loading, setLoading] = useState(true);
  const isAuthenticated = localStorage.getItem("isAuthenticated");

  useEffect(() => {
    FirstShowApi();
  }, []);

  const WatchListFun = async (id) => {
    const payload = {
      movie_id: id,
    };
    if (isAuthenticated === "true") {
      try {
        const response = await toggleWishlist(payload);
        if (response?.data?.status) {
          toast.success(response?.data?.message);
          FirstShowApi();
        }
      } catch (error) {
        toast.error("Something went wrong");
      }
    } else {
      toast.error("Please login to add to watchlist");
    }
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  const search = "";
  const type = "first_show";
  const id = languageId || "";
  const FirstShowApi = async () => {
    try {
      const response = await firstShowMovieList(search, type, id);

      if (response && response.data) {
        setValue(response.data?.data);
      }
    } catch (error) {
      toast.error("Something went wrong");
    }
    setLoading(false);
  };

  const isAllEmpty = (obj) => {
    return Object.values(obj).every(
      (arr) => Array.isArray(arr) && arr.length === 0
    );
  };

  return (
    <div className="mainback">
      <Toaster />

      {loading ? (
        <div className="loader">
          <Spinner animation="border" variant="warning" />
        </div>
      ) : isAllEmpty(value) ? (
        <MoviesConstruction />
      ) : (
        <>
          <HeaderCarousel data={value} />
          <SliderCarousel
            data={value}
            onAddToWatchlist={WatchListFun}
            type={type}
          />
        </>
      )}
    </div>
  );
}
