import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import MoreDetailsHeader from "./MoreDetailsHeader";
import Slider from "./MoredetailSongsPage";
import "../../scss/CrossfadeCarousel.scss";

import { toggleWishlist } from "../../services/home";
import { Toaster, toast } from "react-hot-toast";

export default function ShareMoredetals() {
  const navigate = useNavigate();
  const [trailerId, setTrailerId] = useState(null);
  const isAuthenticated = localStorage.getItem("isAuthenticated");

  const { id } = useParams();
  function decodeBase64(id) {
    return Number(atob(id));
  }
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  const setTrailer = (id) => {
    setTrailerId(decodeBase64(id));
  };

  const trailerWatchHandler = () => {
    if (trailerId) {
      navigate(
        `/movie/streaming/${decodeBase64(
          id
        )}?trailerId=${trailerId}&type=trailer`
      );
    }
  };

  const WatchListFun = async (id) => {
    const payload = {
      movie_id: decodeBase64(id),
    };
    if (isAuthenticated === "true") {
      try {
        const response = await toggleWishlist(payload);
        if (response?.data?.status) {
          toast.success(response?.data?.message);
        }
      } catch (error) {
        toast.error("Something went wrong");
      }
    } else {
      toast.error("Please login to add to watchlist");
    }
  };

  return (
    <div className="mainback">
      <Toaster />
      <MoreDetailsHeader
        id={decodeBase64(id)}
        trailerWatchHandler={trailerWatchHandler}
        onAddToWatchlist={WatchListFun}
      />
      <Slider
        id={decodeBase64(id)}
        setTrailer={setTrailer}
        onAddToWatchlist={WatchListFun}
      />
    </div>
  );
}
