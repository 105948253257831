import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Grid, TextField, Button, Card, Typography } from "@mui/material";

import ContactImg from "../../images/Contact/contact_img.jpeg";
import "../../scss/Contact.scss";
import { submitContactFormApi } from "../../services/contact";
import { Toaster, toast } from "react-hot-toast";

const Contact = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [successMessage, setSuccessMessage] = useState(false);

  const onSubmit = async (data) => {
    const payload = {
      name: data.name,
      email: data.email,
      message: data.message,
      subject: data.subject,
      mobile: data.contactNumber,
      country_code: "+91",
    };

    try {
      const response = await submitContactFormApi(payload);
      if (response?.data?.status) {
        toast.success("Message sent successfully");
        setSuccessMessage(false);
        reset();
      } else {
        toast.error("Failed to send message");
        setSuccessMessage(true);
      }
    } catch (error) {
      toast.error("Failed to send message");
      setSuccessMessage(true);
    }
  };

  return (
    <Grid container className="contact-body">
      <Toaster />
      <Grid container>
        <Grid
          item
          xs={12}
          md={8}
          sx={{
            py: { xs: "1rem", md: 7 },
            px: { xs: "1rem", md: 10 },
          }}
        >
          <Typography variant="para" className="contact-title">
            We are Here to Help!
          </Typography>
          <br />
          <Typography variant="para" className="contact-sub-title">
            Got a question, feedback, or need assistance? Our team is ready to
            help you out! <br /> Fill out the form below, and we'll get back to
            you as soon as possible.
          </Typography>

          <Card sx={{ py: 5 }} className="contact-card">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="form-group">
                <Typography style={{ color: "white" }}>Name</Typography>
                <TextField
                  variant="outlined"
                  fullWidth
                  {...register("name", {
                    required: "Name is required",
                    pattern: {
                      value: /^[A-Za-z\s]+$/,
                      message:
                        "Name should not contain numbers and special characters",
                    },
                  })}
                  error={!!errors.name}
                  helperText={errors.name ? errors.name.message : ""}
                  InputProps={{
                    style: { color: "#fff", backgroundColor: "#161616" },
                  }}
                  style={{ marginBottom: "20px" }}
                />
              </div>
              <div className="form-group">
                <Typography style={{ color: "white" }}>Email</Typography>
                <TextField
                  variant="outlined"
                  fullWidth
                  {...register("email", {
                    required: "Email is required",
                    pattern: {
                      value: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
                      message: "Invalid email address",
                    },
                  })}
                  error={!!errors.email}
                  helperText={errors.email ? errors.email.message : ""}
                  InputProps={{
                    style: { color: "#fff", backgroundColor: "#161616" },
                  }}
                  style={{ marginBottom: "20px" }}
                />
              </div>
              <div className="form-group">
                <Typography style={{ color: "white" }}>
                  Contact Number
                </Typography>
                <TextField
                  variant="outlined"
                  fullWidth
                  {...register("contactNumber", {
                    required: "Contact number is required",
                    pattern: {
                      value: /^[0-9]{10}$/,
                      message: "Contact number should be 10 digits",
                    },
                  })}
                  error={!!errors.contactNumber}
                  helperText={
                    errors.contactNumber ? errors.contactNumber.message : ""
                  }
                  InputProps={{
                    style: { color: "#fff", backgroundColor: "#161616" },
                  }}
                  style={{ marginBottom: "20px" }}
                />
              </div>
              <div className="form-group">
                <Typography style={{ color: "white" }}>Mail Subject</Typography>
                <TextField
                  variant="outlined"
                  fullWidth
                  {...register("subject", {
                    required: "Mail Subject is required",
                  })}
                  error={!!errors.subject}
                  helperText={errors.subject ? errors.subject.message : ""}
                  InputProps={{
                    style: { color: "#fff", backgroundColor: "#161616" },
                  }}
                  style={{ marginBottom: "20px" }}
                />
              </div>
              <div className="form-group">
                <Typography style={{ color: "white" }}>Message</Typography>
                <TextField
                  variant="outlined"
                  fullWidth
                  multiline
                  rows={4}
                  {...register("message", { required: "Message is required" })}
                  error={!!errors.message}
                  helperText={errors.message ? errors.message.message : ""}
                  InputProps={{
                    style: { color: "#fff", backgroundColor: "#161616" },
                  }}
                  style={{ marginBottom: "20px" }}
                />
              </div>
              <Button
                variant="contained"
                type="submit"
                className="contact-button"
                disabled={successMessage}
              >
                {successMessage ? "Please Wait" : "Send Message"}
              </Button>
            </form>
          </Card>
        </Grid>
        <Grid
          item
          xs={12}
          md={4}
          sx={{
            display: { xs: "none", md: "block" },
          }}
        >
          <div
            style={{
              position: "relative",
              width: "100%",
              height: "100%",
              overflow: "hidden",
            }}
          >
            <img
              src={ContactImg}
              alt="Contact"
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
                position: "absolute",
                top: 0,
                left: 0,
              }}
            />
            <div
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                background:
                  "linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, #101010 89.65%)",
              }}
            />
          </div>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Contact;
