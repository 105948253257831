import { Navigate } from "react-router-dom";
import { useAuth } from "./AuthContext";

const ProtectedRoute = ({ children }) => {
  // const { isAuthenticated } = useAuth();
  const isAuthenticated = localStorage.getItem("isAuthenticated");

  if (!isAuthenticated === "true") {
    return <Navigate to="/login" />;
  }

  return children;
};

export default ProtectedRoute;
