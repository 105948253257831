import React, { useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import { Grid, Typography, FormControl, Button } from "@mui/material";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import moment from "moment/moment";

const ResponsiveDatePickers = ({ onChange }) => {
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedTime, setSelectedTime] = useState(null);

  const handleDateChange = (date) => {
    setSelectedDate(date);
    const formattedDate = moment(date).format("YYYY-MM-DD");
    onChange("schedule_date", formattedDate);
  };

  const handleTimeChange = (time) => {
    setSelectedTime(time);
    const formattedTime = time
      ? time.toLocaleTimeString([], {
          hour: "2-digit",
          minute: "2-digit",
          hour12: false,
        })
      : "";
    onChange("schedule_time", formattedTime);
  };

  const clearDateTime = () => {
    setSelectedDate(null);
    setSelectedTime(null);
    onChange("schedule_date", "");
    onChange("schedule_time", "");
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={5}>
        <Typography variant="subtitle1" style={{ color: "#fff" }}>
          Date
        </Typography>
        <FormControl fullWidth variant="outlined">
          <DatePicker
            selected={selectedDate}
            onChange={handleDateChange}
            dateFormat="MMMM d, yyyy"
            minDate={new Date()}
            customInput={
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  backgroundColor: "#000",
                  color: "#fff",
                  borderRadius: "4px",
                  padding: "8px",
                }}
              >
                <CalendarTodayIcon
                  style={{ color: "#fff", marginRight: "8px" }}
                />
                <input
                  type="text"
                  readOnly
                  placeholder="Select Date"
                  value={selectedDate ? selectedDate.toLocaleDateString() : ""}
                  style={{
                    flex: 1,
                    border: "none",
                    background: "none",
                    color: "#fff",
                    outline: "none",
                    textAlign: "left",
                    height: "45px",
                  }}
                />
              </div>
            }
          />
        </FormControl>
      </Grid>
      <Grid item xs={5}>
        <Typography variant="subtitle1" style={{ color: "#fff" }}>
          Time
        </Typography>
        <FormControl fullWidth variant="outlined">
          <DatePicker
            selected={selectedTime}
            onChange={handleTimeChange}
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={5}
            timeCaption="Time"
            dateFormat="h:mm aa"
            customInput={
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  backgroundColor: "#000",
                  color: "#fff",
                  borderRadius: "4px",
                  padding: "8px",
                }}
              >
                <AccessTimeIcon style={{ color: "#fff", marginRight: "8px" }} />
                <input
                  type="text"
                  placeholder="Select Time"
                  readOnly
                  value={
                    selectedTime
                      ? selectedTime.toLocaleTimeString([], {
                          hour: "2-digit",
                          minute: "2-digit",
                        })
                      : ""
                  }
                  style={{
                    flex: 1,
                    border: "none",
                    background: "none",
                    color: "#fff",
                    outline: "none",
                    textAlign: "left",
                    height: "45px",
                  }}
                />
              </div>
            }
          />
        </FormControl>
      </Grid>
      <Grid item xs={2}>
        <Button
          variant="contained"
          color="secondary"
          onClick={clearDateTime}
          sx={{
            backgroundColor: "rgb(254, 213, 48)",
            color: "black",
            mt: 5,
            "&:hover": {
              backgroundColor: "rgb(255, 235, 59)",
            },
          }}
        >
          Clear Date & Time
        </Button>
      </Grid>
      <Grid item xs={12}>
        <div style={{ marginTop: "16px", color: "#fff" }}>
          <p style={{ textAlign: "left" }}>
            <span>
              {selectedDate
                ? ` ${moment(selectedDate).format("ddd, D MMM")}, `
                : ""}
            </span>{" "}
            <span>
              {" "}
              {selectedTime
                ? `${moment(selectedTime).format("hh:mm A")}, `
                : ""}
            </span>{" "}
            India Standard Time, based on your location
          </p>
        </div>
      </Grid>
    </Grid>
  );
};

export default ResponsiveDatePickers;
