// SharePopUpBox.js
import React from "react";
import PropTypes from "prop-types";
import { Dialog, DialogContent, Grid, Typography } from "@mui/material";
import FacebookIcon from "../../images/SocialIcons/Facebook.svg";
import XIcon from "../../images/SocialIcons/X_Platform.svg";
import WhatsAppIcon from "../../images/SocialIcons/WhatsApp.svg";
import InstagramIcon from "../../images/SocialIcons/Instagram.svg";
import { Toaster, toast } from "react-hot-toast";
// import { Button } from "react-bootstrap";
// import crossIcon from "../../images/Icons/crossIcon.svg";

const SharePopUpBox = ({ open, onClose, data }) => {
  const { protocol, host } = window.location; // Get protocol and host from the browser's location
  const baseUrl = `${protocol}//${host}`; // Construct the base URL
  function encodeBase64(data) {
    return btoa(data);
  }
  const newUrl = `${baseUrl}/share/moredetails/${encodeBase64(data)}`;

  const facebookShareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
    newUrl
  )}`;
  const twitterShareUrl = `https://twitter.com/intent/tweet?url=${encodeURIComponent(
    newUrl
  )}`;
  const whatsappShareUrl = `https://wa.me/?text=${encodeURIComponent(newUrl)}`;
  const instagramShareUrl = `https://www.instagram.com/?url=${encodeURIComponent(
    newUrl
  )}`;

  const copyToClipboard = () => {
    navigator.clipboard
      .writeText(newUrl)
      .then(() => {
        toast.success("URL copied to clipboard");
        onClose();
      })
      .catch((error) => {
        console.error("Failed to copy the URL: ", error);
      });
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      sx={{ "& .MuiPaper-root": { backgroundColor: "black" } }}
      maxWidth={"sm"}
      fullWidth={true}
    >
      <Toaster />
      <DialogContent sx={{ color: "#E6E6E6" }}>
        <Grid
          container
          spacing={2}
          className="sharePopUp"
          sx={{ display: "block" }}
        >
          <Grid item xs={12} sx={{ display: "flex" }}>
            <Typography variant="h6" sx={{ color: "#FED530", my: 1.5 }}>
              Share this movie with your friends
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              display: "inline-flex",
              justifyContent: "left",
              alignItems: "left",
              mb: "10px",
              gap: "10px",
              my: 1.5,
            }}
          >
            <a
              href={facebookShareUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={FacebookIcon}
                alt="Facebook"
                style={{ height: "60px" }}
              />
            </a>
            <a href={twitterShareUrl} target="_blank" rel="noopener noreferrer">
              <img src={XIcon} alt="X" style={{ height: "60px" }} />
            </a>
            <a
              href={whatsappShareUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={WhatsAppIcon}
                alt="WhatsApp"
                style={{ height: "60px" }}
              />
            </a>
            <a
              href={instagramShareUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={InstagramIcon}
                alt="Instagram"
                style={{ height: "60px" }}
              />
            </a>
          </Grid>
          <Grid item xs={12} sx={{ my: 1.5 }}>
            <Typography variant="para"> Or Copy the link </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              backgroundColor: "#1E1E1E",
              justifyContent: "space-between",
              alignItems: "space-between",
              display: "flex",
              pt: "0px",
              p: "10px",
              my: 1.5,
              flexDirection: {
                xs: "column",
                sm: "column",
                md: "row",
                lg: "row",
              },
            }}
          >
            <Typography variant="para"> {newUrl} </Typography>
            <Typography
              variant="para"
              sx={{ cursor: "pointer" }}
              onClick={copyToClipboard}
            >
              Copy Link
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

SharePopUpBox.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default SharePopUpBox;
