import React from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import Reel from "../../images/welcome/film-reel.png";
import Ticket from "../../images/welcome/movie-ticket.png";
import { Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import "../../scss/Welcomepage.scss";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const Welcomepage = () => {
  const navigate = useNavigate();

  function handleFirstShow() {
    navigate("/language");
  }
  function handleUploadMovies() {
    navigate("/movieUpload");
  }
  return (
    <div className="full-page-background-welcome">
      <div>
        {/* <Typography className="welcome-header my-4">
          {Strings.WELCOME_TEXT}
        </Typography> */}
        <div className="container">
          {/* <Box> */}
          <Grid container spacing={3}>
            <Grid item xs={12} md={5}>
              <div>
                <Item
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    background: "none",
                    padding: "0 !important",
                    flexDirection: "column",
                  }}
                >
                  <img
                    style={{ maxWidth: "100%", cursor: "pointer" }}
                    className="welcomeimage"
                    src={Reel}
                    alt="Reel"
                    onClick={handleFirstShow}
                  />
                </Item>
                <Typography
                  className="sub-title my-3"
                  style={{
                    fontSize: "18px",
                    fontWeight: "700",
                    lineHeight: "23.44px",
                    textAlign: "center",
                  }}
                >
                  Explore Movies
                </Typography>
              </div>
            </Grid>
            <Grid item xs={12} md={5}>
              <div>
                <Item
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    background: "none",
                    padding: "0 !important",
                    flexDirection: "column",
                  }}
                >
                  <img
                    style={{ maxWidth: "100%", cursor: "pointer" }}
                    className="welcomeimage"
                    src={Ticket}
                    alt="Ticket"
                    onClick={handleUploadMovies}
                  />
                </Item>
                <Typography
                  className="sub-title my-3"
                  style={{
                    fontSize: "18px",
                    fontWeight: "700",
                    lineHeight: "23.44px",
                    textAlign: "center",
                  }}
                >
                  {" "}
                  Upload Movies
                </Typography>
              </div>
            </Grid>
          </Grid>
          {/* </Box> */}
        </div>
      </div>
    </div>
  );
};

export default Welcomepage;
