import React, { useEffect, useState, useCallback } from "react";
import {
  Dialog,
  TextField,
  DialogContent,
  List,
  ListItem,
  Avatar,
  ListItemText,
  InputAdornment,
} from "@mui/material";
import { Button } from "react-bootstrap";
import SearchIcon from "@mui/icons-material/Search";
import { makeStyles } from "@mui/styles";
import { getSearchFilter } from "../../services/movieupload";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles({
  dialogPaper: {
    position: "fixed",
    top: "-192px !important",
    left: "25% !important",
    transform: "translateX(-50%)",
    margin: 0,
    width: "820px",
    height: "278px",
    padding: "16px",
    borderRadius: "12px",
    background: "rgba(30, 30, 30, 1)  !important",
    overflow: "hidden",
    "& .MuiDialogContent-root": {
      overflow: "hidden",
    },
  },
  list: {
    maxHeight: "200px", // Adjust height as needed
    overflowY: "auto",
    overflowX: "hidden",
  },
  listItem: {
    width: "788px !important",
    height: "80px !important",
    padding: "10px 12px !important",
    borderRadius: "8px",
    border: "1px solid #161616 !important",
    background: "#161616 !important",
    marginBottom: "8px !important",
  },
  textFieldContainer: {
    position: "sticky",
    top: 0,
    background: "#1e1e1e",
    zIndex: 1,
    paddingBottom: "16px",
    marginBottom: "20px",
  },
  textField: {
    "& .MuiOutlinedInput-root": {
      fontFamily: "sans-serif",
      color: "rgba(255, 255, 255, 0.6)",
      fontSize: "16px",
      fontWeight: "400",
      lineHeight: "24px",
      height: "60px",
      padding: "10px 14px",
      width: "90%",
      borderRadius: "8px",
      border: "1px solid rgba(255, 255, 255, 0.5)",
      background: "#333333",
      position: "fixed",
      "& fieldset": {
        border: "none",
      },
      "&.Mui-focused fieldset": {
        border: "none",
      },
      "& .MuiInputBase-input-root": {
        background: "#333333",
      },
      "& .MuiInputAdornment-root": {
        background: "#333333",
        color: "white",
      },
    },
  },
});

const SearchCard = () => {
  const [open, setOpen] = useState(false);
  const [query, setQuery] = useState("");
  const [filteredSuggestion, setFilteredSuggestion] = useState(null);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(null);
  const [loading, setLoading] = useState(false);
  const classes = useStyles();
  const navigate = useNavigate();

  const handleSearchChange = (e) => {
    setQuery(e.target.value);
    setPage(1);
    setTotalPages(1);
    setFilteredSuggestion([]);
  };

  useEffect(() => {
    const language_id = localStorage.getItem("languageId");
    console.log(language_id, "language_idlanguage_idlanguage_id");
    if (query?.trim()) {
      fetchData(language_id, query, page);
    }
  }, [query, page]);

  useEffect(() => {
    if (open === false) {
      setFilteredSuggestion(null);
    }
  }, [open]);

  async function fetchData(language_id, query, page) {
    if (loading) return;
    setLoading(true);
    const lan =
      language_id === null || language_id === undefined ? "" : language_id;
    try {
      const response = await getSearchFilter(lan, query, page);
      if (response?.data?.status) {
        setFilteredSuggestion((prev) => [
          ...prev,
          ...response?.data?.data?.movies,
        ]);
        setTotalPages(response?.data?.data?.pagination?.totalPages);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  const handleScroll = useCallback(
    (event) => {
      const { scrollTop, scrollHeight, clientHeight } = event.currentTarget;
      if (
        scrollHeight - scrollTop === clientHeight &&
        !loading &&
        page < totalPages
      ) {
        setPage((prevPage) => prevPage + 1); // Load next page if not on last page
      }
    },
    [loading, page, totalPages]
  );

  return (
    <div>
      <Button
        variant="text"
        onClick={() => setOpen(!open)}
        color="primary"
        style={{ color: "inherit" }}
      >
        <SearchIcon style={{ color: "inherit", fontSize: "19.76px" }} />
      </Button>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        PaperProps={{ className: classes.dialogPaper }}
        maxWidth="820px"
      >
        <DialogContent>
          <div className={classes.textFieldContainer}>
            <TextField
              className={classes.textField}
              variant="outlined"
              placeholder="Search"
              fullWidth
              onChange={handleSearchChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment
                    position="start"
                    style={{
                      background: "none",
                    }}
                  >
                    <SearchIcon
                      style={{ color: "white", fontSize: "19.76px" }}
                    />
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <List className={classes.list} onScroll={handleScroll}>
            {query?.trim() ? (
              loading ? (
                <ListItem
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    fontSize: "16px",
                    fontWeight: "400",
                    lineHeight: "20.83px",
                    color: "#B0B0B0",
                    fontFamily: "sans-serif",
                    margin: "1rem",
                  }}
                >
                  Loading...
                </ListItem>
              ) : filteredSuggestion?.length > 0 ? (
                filteredSuggestion.map((suggestion, index) => (
                  <ListItem
                    button
                    key={index}
                    onClick={() => {
                      setOpen(false);
                      navigate(`/moredetails/${suggestion?.id}`);
                    }}
                    className={classes.listItem}
                  >
                    <Avatar
                      style={{
                        width: "60px",
                        height: "60px",
                        borderRadius: "6px",
                        objectFit: "contain",
                      }}
                      src={suggestion?.thumbnail}
                      variant="square"
                    />
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <ListItemText
                        style={{
                          fontSize: "16px",
                          fontWeight: "500",
                          lineHeight: "20.83px",
                          textAlign: "left",
                          color: "#FFFFFF",
                          fontFamily: "sans-serif",
                          margin: "1rem",
                        }}
                        primary={`${suggestion?.title}`}
                      />
                      <span
                        style={{
                          fontSize: "16px",
                          fontWeight: "400",
                          lineHeight: "20.83px",
                          textAlign: "left",
                          color: "#B0B0B0",
                          fontFamily: "sans-serif",
                          margin: "1rem",
                        }}
                      >
                        ({suggestion?.Language?.name})
                      </span>
                    </div>
                  </ListItem>
                ))
              ) : (
                <ListItem
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    fontSize: "16px",
                    fontWeight: "400",
                    lineHeight: "20.83px",
                    color: "#B0B0B0",
                    fontFamily: "sans-serif",
                    margin: "1rem",
                  }}
                >
                  No suggestions found
                </ListItem>
              )
            ) : (
              <ListItem
                style={{
                  display: "flex",
                  justifyContent: "center",
                  fontSize: "16px",
                  fontWeight: "400",
                  lineHeight: "20.83px",
                  color: "#B0B0B0",
                  fontFamily: "sans-serif",
                  margin: "1rem",
                }}
              >
                Search for Movie
              </ListItem>
            )}
          </List>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default SearchCard;
