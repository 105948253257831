import React, { useEffect, useState } from "react";
import HeaderCarousel from "../HomePage/HeaderCarousel";
import SliderCarousel from "../HomePage/SlidersCarousel";
import "../../scss/CrossfadeCarousel.scss";
   
import {
  firstShowMovieList,
  movielist,
  toggleWishlist,
} from "../../services/home";
import Spinner from "react-bootstrap/Spinner";
import { useLocation } from "react-router-dom";
import toast from "react-hot-toast";
import CategorisedMovieList from "./CategorisedMovieList";

export default function CategorisedMovies() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const type = queryParams.get("type");
  const category = queryParams.get("category");
  const languageId = localStorage.getItem("languageId") || "";

  const [movies, setMovies] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchMovies = async () => {
    setLoading(true);
    try {
      const response = await movielist(type, languageId, category);
      if (response && response?.data && response?.data?.status) {
        setMovies(response?.data?.data?.movies[category]);
      }
    } catch (error) {
      if (error?.code === "ERR_NETWORK") {
        toast.error("Please check your internet connection");
        return;
      }
      toast.error(error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchMovies();
  }, [type, languageId, category]);

  return (
    <div>
      {loading ? (
        <div className="loader">
          <Spinner animation="border" variant="warning" />
        </div>
      ) : (
        <CategorisedMovieList
          navigateTo="home"
          movies={movies}
          seeMore={fetchMovies}
        />
      )}
    </div>
  );
}
