import { ApiService } from "./ApiService/ApiService";
import { urlConfig } from "../config/urlConfig";

export const getanalytics = (id) => {
  const url = `${urlConfig.analytics?.getanalytics}${id}`;
  return ApiService.get(url);
};

export const movetoSubscription = (id) => {
  const url = `${urlConfig.analytics?.movetoSubscription}`;
  let payload = {
    movie_id: id,
  };
  return ApiService.post(url, payload);
};
