import React from "react";
   
import "../../scss/MovieConstruction.scss";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";

const MoviesConstruction = ({
  navigateTo = "language",
  showLanguageText = true,
}) => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate(`/${navigateTo}`);
  };

  return (
    <div className="empty-state">
      <div className="empty-state-content">
        <h2>No movies available</h2>
        {showLanguageText && (
          <p>The selected language does not have any movies available.</p>
        )}
        <Button
          className="Continue-Button-intropage"
          variant="secondary"
          onClick={handleClick}
        >
          Back
        </Button>
      </div>

    </div>
  );
};

export default MoviesConstruction;
