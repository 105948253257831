import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "../../scss/MoreDetailsSongs.scss";
import { Typography, Tooltip, Grid } from "@mui/material";
import { responsive, MoreSlidesTitle } from "../HomePage/utilite";
import lack from "../../images/moredetails/Lock.svg";
import save from "../../images/moredetails/Save.svg";
import WishedList from "../../images/moredetails/WishedList.svg";
import info from "../../images/moredetails/Info.svg";
import share from "../../images/moredetails/Share.svg";
import clock from "../../images/moredetails/clock.svg";
import play from "../../images/moredetails/play.svg";

import { homeview, getVideoUrl } from "../../services/home";
import toast from "react-hot-toast";
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import SharePopUpBox from "../../common/sharePopUp/SharePopUp";

const Slider = ({ id, setTrailer, onAddToWatchlist }) => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [movie, setMovie] = useState(null);
  const [upcomingMovies, setUpcomingMovies] = useState([]);

  const [videoUrl, setVideoUrl] = useState(null);
  const [playVideo, setPlayVideo] = useState(false);
  const [showFullDescription, setShowFullDescription] = useState(false);

  useEffect(() => {
    fetchDetails();
  }, [id]);

  const fetchDetails = async () => {
    try {
      const response = await homeview(id);
      if (response && response.data) {
        const { movie, upcoming_movies } = response.data.data;
        setMovie(movie);
        setUpcomingMovies(upcoming_movies);
        setTrailer(movie?.Trailer?.id || null);
      }
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  const handlePlayClick = async (songId) => {
    navigate(`/movie/streaming/${id}?songId=${songId}&type=song`);
  };

  const handleMovieClick = (movieId) => {
    navigate(`/moredetails/${movieId}`);
  };

  const toggleDescription = () => {
    setShowFullDescription(!showFullDescription);
  };

  const convertSecondsToHrMin = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    return hours > 0 ? `${hours} Hr ${minutes} Min` : `${minutes} Min`;
  };

  const convertSecondsToMinSec = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return minutes > 0
      ? `${minutes} Min ${remainingSeconds} Sec`
      : `${remainingSeconds} Sec`;
  };

  const handleSeeMore = (id) => {
    navigate(`/moredetails/${id}`, { state: { id } });
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const [shareDetials, setShareDetials] = useState(false);
  const [shareMovie, setShareMovie] = useState("");

  const OpenSharePopUp = (item) => {
    setShareMovie(item?.id);
    setShareDetials(true);
  };
  const CloseSharePopUp = () => {
    setShareDetials(false);
  };

  const renderTitleWithTooltip = (title) => {
    if (title.length > 25) {
      return (
        <Tooltip title={title} placement="top">
          <Typography variant="h3">{`${title.substring(0, 25)}...`}</Typography>
        </Tooltip>
      );
    } else {
      return <Typography variant="h3">{title}</Typography>;
    }
  };

  const handleAddWatchList = async (id) => {
    await onAddToWatchlist(id);
  };

  return (
    <div className="Slider">
      {MoreSlidesTitle?.length > 0 &&
        MoreSlidesTitle?.map((section, sectionIndex) => (
          <>
            <div className="movie-songs">
              {movie && (
                <div style={{ color: "white", marginTop: "20px" }}>
                  <Typography
                    variant="h3"
                    sx={{
                      marginTop: "10px",
                      color: "white",
                      fontFamily: "DM Sans",
                      fontWeight: "500",
                      display: "flex",
                      justifyContent: "start",
                      marginBottom: "0",
                      lineHeight: "41.66px",
                      fontSize: {
                        xs: "20px",
                        sm: "20px",
                        md: "32px",
                        lg: "32px",
                      },
                    }}
                  >
                    Cast and Crew
                  </Typography>{" "}
                  {movie?.director && (
                    <div>
                      {/* <h4>Directors</h4> */}
                      <Typography
                        variant="h3"
                        sx={{
                          marginTop: "10px",
                          color: "white",
                          fontFamily: "DM Sans",
                          fontWeight: "500",
                          display: "flex",
                          justifyContent: "start",
                          marginBottom: "0",
                          fontSize: {
                            xs: "18px",
                            sm: "18px",
                            md: "24px",
                            lg: "24px",
                          },
                        }}
                      >
                        Directors
                      </Typography>{" "}
                      <p>{movie?.director}</p>
                    </div>
                  )}
                  {movie?.producer_1 || movie?.producer_2 ? (
                    <div>
                      <Typography
                        variant="h3"
                        sx={{
                          marginTop: "10px",
                          color: "white",
                          fontFamily: "DM Sans",
                          fontWeight: "500",
                          display: "flex",
                          justifyContent: "start",
                          marginBottom: "0",
                          fontSize: {
                            xs: "18px",
                            sm: "18px",
                            md: "24px",
                            lg: "24px",
                          },
                        }}
                      >
                        Producers
                      </Typography>{" "}
                      <p>
                        {movie?.producer_1 && movie.producer_1}
                        {movie?.producer_1 && movie?.producer_2 && ", "}
                        {movie?.producer_2 && movie.producer_2}
                      </p>
                    </div>
                  ) : null}
                  {(movie?.cast_1 || movie?.cast_2 || movie?.cast_3) && (
                    <div>
                      <Typography
                        variant="h3"
                        sx={{
                          marginTop: "10px",
                          color: "white",
                          fontFamily: "DM Sans",
                          fontWeight: "500",
                          display: "flex",
                          justifyContent: "start",
                          marginBottom: "0",
                          fontSize: {
                            xs: "18px",
                            sm: "18px",
                            md: "24px",
                            lg: "24px",
                          },
                        }}
                      >
                        Starring
                      </Typography>
                      <p>
                        {movie?.cast_1 && movie.cast_1}
                        {movie?.cast_1 &&
                          (movie?.cast_2 || movie?.cast_3) &&
                          ", "}
                        {movie?.cast_2 && movie.cast_2}
                        {movie?.cast_2 && movie?.cast_3 && ", "}
                        {movie?.cast_3 && movie.cast_3}
                      </p>
                    </div>
                  )}
                </div>
              )}
            </div>
            {/* Songs  */}
            {movie && movie?.Songs?.length > 0 && (
              <>
                <Typography className="header-text" >Songs</Typography>
                <div className="movie-songs">
                  {movie &&
                    movie.Songs &&
                    movie.Songs.map((song, index) => (
                      <div className="movie-song" key={index}>
                        <div className="image-container">
                          <div className="image-wrapper">
                            <div
                              className="caption"
                              style={{
                                backgroundImage: `url(${song.thumbnail})`,
                                backgroundSize: "cover",
                                backgroundRepeat: "no-repeat",
                                opacity: "0.9",
                                backgroundColor:
                                  "linear-gradient(180deg, rgba(0, 0, 0, 0) 80%, #000000 86%)",
                              }}
                            >
                              <Grid
                                container
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <Grid item xs={10}>
                                  {renderTitleWithTooltip(song?.title)}

                                  <Typography variant="para">
                                    <AccessTimeOutlinedIcon
                                      style={{ marginRight: "10px" }}
                                    />
                                    {convertSecondsToMinSec(song?.duration)}
                                  </Typography>
                                </Grid>
                                <Grid item xs={2}>
                                  <img
                                    src={play}
                                    alt="play"
                                    style={{ height: "40px", width: "40px" }}
                                    onClick={() => handlePlayClick(song.id)}
                                  />
                                </Grid>
                              </Grid>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              </>
            )}
            {upcomingMovies?.length > 0 && (
              <div
                className="mainSlider-header"
                key={sectionIndex}
                style={{ padding: "20px 70px" }}
              >
                <Typography
                  className="header-text"
                  style={{
                    paddingLeft: "0rem",
                  }}
                >
                  {section.header}
                </Typography>
                <div className="parent">
                  <Carousel
                    responsive={responsive}
                    autoPlay={false}
                    swipeable={true}
                    draggable={true}
                    showDots={false}
                    infinite={false}
                    arrows={upcomingMovies.length > 6}
                    partialVisible={false}
                    dotListClass="custom-dot-list-style"
                  >
                    {/* Upcoming releases */}
                    {section?.header !== "Songs" &&
                      upcomingMovies &&
                      upcomingMovies.map((upcomingMovie, index) => (
                        <div className="slider" key={index}>
                          <div className="image-container">
                            <div className="image-wrapper">
                              <img
                                src={upcomingMovie.thumbnail}
                                alt={`movie-${index}`}
                              />
                              <div className="image-overlay">
                                <div className="overlay-content">
                                  <Typography
                                    variant="body2"
                                    className="UA-c"
                                    sx={{ mb: 2 }}
                                  >
                                    {upcomingMovie?.Certificate?.name || "N/A"}
                                  </Typography>
                                  <Typography
                                    variant="body2"
                                    className="icon-text-container"
                                  >
                                    <span>
                                      <img
                                        src={clock}
                                        alt=""
                                        style={{
                                          height: "15px",
                                          width: "15px",
                                          marginRight: "5px",
                                        }}
                                      />
                                    </span>
                                    {convertSecondsToHrMin(
                                      upcomingMovie?.duration
                                    )}
                                  </Typography>
                                  <Typography variant="h6">
                                    {upcomingMovie.title}
                                  </Typography>
                                  <Typography
                                    variant="body2"
                                    className={
                                      showFullDescription
                                        ? "full-description"
                                        : "truncated-description"
                                    }
                                    onClick={toggleDescription}
                                  >
                                    {showFullDescription
                                      ? upcomingMovie.description
                                      : `${upcomingMovie.description.substring(
                                          0,
                                          100
                                        )}....`}
                                  </Typography>
                                  <div
                                    className="icon-container"
                                    style={{ marginTop: "10px" }}
                                  >
                                    <div className="left-icons">
                                      <Tooltip
                                        title="Pay/Rent Now"
                                        placement="top"
                                      >
                                        <img
                                          src={lack}
                                          alt=""
                                          style={{
                                            height: "40px",
                                            width: "40px",
                                          }}
                                          onClick={() =>
                                            handleSeeMore(upcomingMovie?.id)
                                          }
                                        />
                                      </Tooltip>
                                      <Tooltip
                                        title="Watch List"
                                        placement="top"
                                      >
                                        <img
                                          src={
                                            !upcomingMovie?.is_saved
                                              ? save
                                              : WishedList
                                          }
                                          alt=""
                                          style={{
                                            width: "40px",
                                            height: "40px",
                                          }}
                                          onClick={() =>
                                            handleAddWatchList(
                                              upcomingMovie?.id
                                            )
                                          }
                                        />
                                      </Tooltip>
                                      <Tooltip title="Share" placement="top">
                                        <img
                                          src={share}
                                          alt=""
                                          style={{
                                            width: "40px",
                                            height: "40px",
                                          }}
                                          onClick={() =>
                                            OpenSharePopUp(upcomingMovie)
                                          }
                                        />
                                      </Tooltip>
                                      <Tooltip
                                        title="More Info"
                                        placement="top"
                                      >
                                        <img
                                          src={info}
                                          alt=""
                                          className="right-icon"
                                          style={{
                                            width: "40px",
                                            height: "40px",
                                          }}
                                          onClick={() =>
                                            handleSeeMore(upcomingMovie?.id)
                                          }
                                          // onClick={() => handleMovieClick(upcomingMovie.id)}
                                        />
                                      </Tooltip>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                  </Carousel>
                </div>
                {/* Cast and Crew in Details Page  */}
              </div>
            )}
          </>
        ))}
      <SharePopUpBox
        open={shareDetials}
        onClose={CloseSharePopUp}
        data={shareMovie}
      />
    </div>
  );
};

export default Slider;
