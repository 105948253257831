import React from "react";
   
import "../../scss/MovieConstruction.scss";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";

const MoviesConstruction = () => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate("/home");
  };

  return (
    <div className="empty-state">
      <div className="empty-state-content">
        <h2>OOPS</h2>
        <p>You Dont Have Access To This Page</p>
        <Button
          className="Continue-Button-intropage"
          variant="secondary"
          onClick={handleClick}
        >
          Back
        </Button>
      </div>
    </div>
  );
};

export default MoviesConstruction;
