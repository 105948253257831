// src/components/login/Login.jsx
import React, { useState } from "react";
import Typography from "@mui/material/Typography";
import { useGoogleLogin } from "@react-oauth/google";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import "../scss/login.scss";
import { Link, useNavigate } from "react-router-dom";
import Strings from "../common/string";
import Button from "react-bootstrap/Button";
import google from "../images/welcome/Google.svg";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "react-toastify/dist/ReactToastify.css";
import { google_login, sendOtp } from "../../src/services/auth";
import { Toaster, toast } from "react-hot-toast";
import { useDispatch } from "react-redux";
import { Backdrop, CircularProgress } from "@mui/material";
import { getprofileDetailS } from "../services/account";

export default function Login() {
  const [mobileNumber, setMobileNumber] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleskip = () => {
    navigate("/home");
  };

  const handleContinue = async () => {
    setLoading(true);

    try {
      const numberWithoutCountryCode = mobileNumber.replace(/^\d{1,2}/, "");
      const response = await sendOtp(numberWithoutCountryCode);
      if (response.data?.status === true) {
        toast.success("OTP sent successfully!");
        localStorage.setItem("otp_token", response?.data?.data?.otp_token);
        navigate("/otp", {
          state: { mobileNumber: numberWithoutCountryCode },
        });
      } else {
        toast.error("Please try again.");
      }
    } catch (err) {
      setError(err);
      console.error("Error sending OTP:", err);
      toast.error("Failed to send OTP. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const login = useGoogleLogin({
    onSuccess: async (response) => {
      setLoading(true);
      const googleToken = response.access_token;
      setIsLoggedIn(true);
      try {
        const apiResponse = await dispatch(
          google_login({
            google_token: googleToken,
          })
        );
        if (apiResponse && apiResponse.data && apiResponse.data.data) {
          const data = apiResponse.data.data;
          localStorage.setItem("token", data.access_token);
          localStorage.setItem("role", data.user.role);
          localStorage.setItem("isAuthenticated", true);
          const res = await dispatch(getprofileDetailS());
          console.log(res);
          if (data.login_status === false) {
            navigate("/account", {
              state: { data: data, login_status: false },
            });
          } else {
            const userName = data.user ? data.user.name : data.producer.name;
            localStorage.setItem("user_name", userName);
            localStorage.setItem("isAuthenticated", true);
            localStorage.setItem("profile", data.user.profile_pic);
            localStorage.setItem("userData", JSON.stringify(data?.user));
            if (data?.user?.role === "producer") {
              navigate("/welcome", { replace: true });
            } else {
              navigate("/language", { replace: true });
            }
          }
        } else {
          throw new Error("Invalid response from server");
        }
      } catch (apiError) {
        toast.error(apiError.message);
        // toast.error(apiError?.response?.data.message);

      } finally {
        setLoading(false);
      }
    },
    onError: (error) => {
      console.error("Google login failed: ", error);
      toast.error("Google login failed. Please try again.");
    },
  });

  return (
    <div className="full-page-background-login">
      <Toaster />
      <div className="container">
        <div className="background">
          <div className="cardvalue">
            <Button className="skip" onClick={handleskip}>
              {Strings.SKIP_FOR_NOW}
              <KeyboardDoubleArrowRightIcon style={{ marginTop: "2px" }} />
            </Button>

            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              className="login-Header"
            >
              {Strings.LOGIN}
            </Typography>
            <p
              id="modal-modal-title"
              component="h2"
              style={{
                color: "#fff",
                display: "flex",
                justifyContent: "center",
                fontFamily: "DM Sans",
                fontWeight: "400",
                fontSize: "16px",
                margin: "16px 0px",
              }}
            >
              {Strings.SUB_HEAD}
            </p>
            <div className="login">
              <div>
                <PhoneInput
                  country={"in"}
                  value={mobileNumber}
                  onChange={(phone) => setMobileNumber(phone)}
                  className="inputphone"
                  onlyCountries={["in"]}
                  disableDropdown={true}
                />
              </div>

              <Button
                className="Continue-Button-login mt-3  mb-4"
                variant="secondary"
                onClick={handleContinue}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  fontFamily: "DM Sans",
                  fontWeight: "500",
                  fontSize: "20px",
                }}
              >
                {Strings.CONTINUE_BUTTON}
              </Button>
              {!isLoggedIn ? (
                <Button
                  className="google my-4"
                  onClick={() => login()}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "10px 20px",
                    fontFamily: "DM Sans",
                    fontWeight: "500",
                    fontSize: "16px",
                    color: "#fff",
                    backgroundColor: "#4285F4",
                    border: "none",
                    borderRadius: "4px",
                  }}
                >
                  <img
                    src={google}
                    alt="Google logo"
                    style={{
                      marginRight: "10px",
                      width: "20px",
                      height: "20px",
                    }}
                  />
                  {Strings.CONTINUE_WITH_GOOGLE}
                </Button>
              ) : (
                <Button
                  className="google my-4"
                  // onClick={handleLogout}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "10px 20px",
                    fontFamily: "DM Sans",
                    fontWeight: "500",
                    fontSize: "16px",
                    color: "#fff",
                    backgroundColor: "#4285F4",
                    border: "none",
                    borderRadius: "4px",
                    margin: "16px 0px",
                  }}
                >
                  Please Wait
                </Button>
              )}
            </div>
            <p
              id="modal-modal-title"
              component="h2"
              style={{
                color: "#fff",
                marginTop: "20px",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "25.6px",
                letterSpacing: "0.01em",
                textAlign: "left",
                margin: "16px 0px",
              }}
            >
              By Logging in you agree to the{" "}
              <Link
                to="/terms-condition"
                style={{ color: "#FED530", fontSize: "16px" }}
              >
                Terms & Condition
              </Link>
              {" and "}
              <Link
                to="/privacy-policy"
                style={{ color: "#FED530", fontSize: "16px" }}
              >
                Privacy policy
              </Link>
            </p>
          </div>
        </div>
      </div>
      <Backdrop
        open={loading}
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
}
