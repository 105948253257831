import { ApiService } from "./ApiService/ApiService";
import { urlConfig } from "../config/urlConfig";
import {
  signupFailure,
  signupRequest,
  signupSuccess,
} from "../store/sample/action";

export const sendOtp = (mobile) => {
  return ApiService.post(urlConfig.auth.sendOtp, { mobile });
};

// export const verifyOtp = (otp, otp_token) => {
//   return ApiService.post(urlConfig.auth.verifyOtp, { otp, otp_token });
// };
export const resendOtp = (otp_token) => {
  return ApiService.post(urlConfig.auth.resendOtp, {
    otp_token,
  });
};
export const signup = (payload) => {
  return ApiService.post(urlConfig.auth.signup, payload);
};
// export const google_login = (payload) => {
//   return ApiService.post(urlConfig.auth.google_login, payload);
// };

export const verifyOtp = (otp, otp_token) => {
  return async (dispatch) => {
    dispatch(signupRequest());
    try {
      const response = await ApiService.post(urlConfig.auth.verifyOtp, {
        otp,
        otp_token,
      });
      dispatch(signupSuccess(response.data));
      return response;
    } catch (error) {
      dispatch(signupFailure(error));
    }
  };
};

export const google_login = (payload) => {
  return async (dispatch) => {
    dispatch(signupRequest());
    try {
      const response = await ApiService.post(
        urlConfig.auth.google_login,
        payload
      );
      dispatch(signupSuccess(response.data));
      return response;
    } catch (error) {
      dispatch(signupFailure(error));
    }
  };
};
