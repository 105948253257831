// src/components/Transactions.jsx
import React, { useEffect, useState } from "react";
import { Box, Typography, Grid, Button } from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import "../../scss/Transactions.scss";
import { getUploadedMovies } from "../../services/account";
import { ArrowForwardIos } from "@mui/icons-material";
import NodataImage from "./NodataImage";
import ActionforUploadedMovie from "./ActionforUploadedMovie";
import { useNavigate } from "react-router-dom";
import ActionForSheduledMovies from "./ActionForSheduledMovies";
import moment from "moment";

const UploadedMovies = () => {
  const [uploadedMovies, setUploadedMovies] = useState([]);
  const [scheduleduploadsMovies, setScheduledUploadsMovies] = useState([]);
  const navigate = useNavigate();
  const [selectedMovie, setSelectedMovie] = useState(null);

  const handleCloseModal = () => {
    setSelectedMovie(null);
  };

  const handleCancelSchedule = (movie) => {
    setSelectedMovie(movie?.id);
  };

  const handleEditMovie = (movie) => {
    setSelectedMovie(movie?.id);
  };

  useEffect(() => {
    fetchData();
  }, []);
  async function fetchData() {
    try {
      const response = await getUploadedMovies();
      if (response?.data?.status) {
        setUploadedMovies(response?.data?.data?.uploaded_movies);
        setScheduledUploadsMovies(response?.data?.data?.scheduled_uploads);
      }
    } catch (error) {
      console.error("Error fetching uploaded movies:", error);
    }
  }
  return (
    <Box className="transactions">
      <Box
        className="Transactions-heading"
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div>
          <Typography variant="h5">Uploaded Movies</Typography>
          <Typography variant="subtitle1">
            List of movies uploaded in your account
          </Typography>
        </div>
      </Box>
      <Box>
        <div>
          <Typography className="upload-movie-tex">
            Scheduled Uploads <ArrowForwardIosIcon />
          </Typography>
        </div>
      </Box>
      {scheduleduploadsMovies?.length > 0 ? (
        scheduleduploadsMovies?.map((movie, index) => (
          <Box key={index} className="transaction-card">
            <img
              src={movie?.thumbnail}
              alt={movie?.title}
              className="transaction-image"
            />
            <Box className="transaction-info">
              <Typography variant="body2" className="rent-label">
                {movie?.movie_plan
                  ?.replace(/_/g, " ")
                  ?.replace(/\b\w/g, (char) => char?.toUpperCase())}
              </Typography>
              <Typography variant="subtitle1" className="transaction-title">
                {movie?.title}
              </Typography>
              <Typography variant="body2" className="transaction-date">
                Uploaded Date and Time:{" "}
                {moment(movie?.schedule_date).format("DD-MM-YYYY ")} 
                {moment(`2024-09-12T${movie?.schedule_time}`).format("| hh:mm A")} 
              </Typography>
            </Box>
            <ActionForSheduledMovies
              selectedMovie={selectedMovie}
              onEdit={() => handleEditMovie(movie)}
              onCancel={() => handleCancelSchedule(movie)}
              handleCloseModal={handleCloseModal}
              movie={movie}
              fetchData={fetchData}
            />
          </Box>
        ))
      ) : (
        <NodataImage data={"No Scheduled Movies Found"} />
      )}
      <Box>
        <div>
          <Typography className="upload-movie-tex">
            Uploaded
            <ArrowForwardIosIcon />
          </Typography>
        </div>
      </Box>
      {uploadedMovies.length > 0 ? (
        uploadedMovies.map((movie, index) => (
          <Box key={index} className="transaction-card">
            <img
              src={movie?.thumbnail}
              alt={movie?.title}
              className="transaction-image"
            />
            <Box className="transaction-info">
              <Typography variant="body2" className="rent-label">
                {movie?.movie_plan
                  ?.replace(/_/g, " ")
                  ?.replace(/\b\w/g, (char) => char?.toUpperCase())}
              </Typography>
              <Typography variant="subtitle1" className="transaction-title">
                {movie?.title}
              </Typography>
              <Typography variant="body2" className="transaction-date">
                Uploaded Date and Time :{" "}
                {moment(movie?.createdAt).format("DD-MM-YYYY | hh:mm A")}
              </Typography>
            </Box>
            <ActionforUploadedMovie
              selectedMovie={selectedMovie}
              onEdit={() => handleEditMovie(movie)}
              onCancel={() => handleCancelSchedule(movie)}
              handleCloseModal={handleCloseModal}
              movie={movie}
              fetchData={fetchData}
            />
            <Button
              variant="text"
              color="inherit"
              onClick={() => {
                navigate(`/analytic/${movie?.id}`);
              }}
            >
              <Typography>
                <ArrowForwardIos />
              </Typography>
            </Button>
          </Box>
        ))
      ) : (
        <NodataImage data={"No Uploaded Movie Found"} />
      )}
    </Box>
  );
};

export default UploadedMovies;
