import React from "react";
import MovieAnalyticBanner from "./MovieAnalyticBanner";
import BookingHistoryTable from "./BookingHistoryTable";
import GraphAnalytics from "./GraphAnalytics";

const MovieAnalyticsPage = () => {
  return (
    <>
      <MovieAnalyticBanner />
      <BookingHistoryTable />
      <GraphAnalytics />
    </>
  );
};

export default MovieAnalyticsPage;
