import React from 'react'
import { Grid, Typography } from "@mui/material";
import ScreenImg3 from "../../images/screen3.png";
import "../../scss/Contact.scss";

const ViewersSays = () => {
    return (
        <Grid container className="screen-body pt-lg-5">
            <Grid container>
                <Grid
                    item
                    xs={12}
                    md={6}
                    sx={6}
                >
                    <div>
                        <img
                            src={ScreenImg3}
                            alt="Contact"
                        />
                    </div>
                </Grid>
                <Grid
                    item
                    xs={12}
                    md={6}
                    sx={{
                        py: { xs: "1rem", md: 7 },
                        px: { xs: "1rem", md: 10 },
                    }}
                >
                    <div className='mt-5 pt-3'>
                        <Typography variant="para" className="screenPara1">
                            VIEWER
                        </Typography>
                        <br />
                        <Typography variant="para" className="screenPara2">
                            SAYS
                        </Typography>
                        <br />
                        <Typography variant="para" className="screenPara3">
                            As a film enthusiast, I want to see every new release, but time
                            constraints and other obstacles make it difficult. Some films I’m
                            eager to watch might not be available nearby. Rural areas lack
                            theaters, and city life often leaves no time for them. Additionally,
                            most OTT platforms stream movies only after a month, by which
                            time my interest fades. Thus, watching films on release day
                            remains a dream.
                        </Typography>
                    </div>
                </Grid>

            </Grid>
        </Grid>
    )
}

export default ViewersSays