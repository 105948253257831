export const data = [
    { id: 1, name: 'Loki Bright', date: '26-Aug-2023', email: 'LokiBright@zerozilla.com', contact: '+91 12345 67890', avatar: 'path_to_avatar' },
    { id: 2, name: 'Sara Woodman', date: '26-Aug-2023', email: 'SaraWoodman@zerozilla.com', contact: '+91 12345 67890', avatar: 'path_to_avatar' },
    { id: 3, name: 'Mason Philips', date: '26-Aug-2023', email: 'MasonPhilips@zerozilla.com', contact: '+91 12345 67890', avatar: 'path_to_avatar' },
    { id: 4, name: 'Abby Smith', date: '26-Aug-2023', email: 'AbbySmith@zerozilla.com', contact: '+91 12345 67890', avatar: 'path_to_avatar' },
    { id: 5, name: 'Archie Young', date: '26-Aug-2023', email: 'ArchieYoung@zerozilla.com', contact: '+91 12345 67890', avatar: 'path_to_avatar' },
    { id: 6, name: 'Drew Cano', date: '26-Aug-2023', email: 'DrewCano@zerozilla.com', contact: '+91 12345 67890', avatar: 'path_to_avatar' },
    { id: 7, name: 'James Dunn', date: '26-Aug-2023', email: 'JamesDunn@zerozilla.com', contact: '+91 12345 67890', avatar: 'path_to_avatar' },
    { id: 8, name: 'Eve Leroy', date: '26-Aug-2023', email: 'EveLeroy@zerozilla.com', contact: '+91 12345 67890', avatar: 'path_to_avatar' },
    { id: 9, name: 'Ryan Fox', date: '26-Aug-2023', email: 'RyanFox@zerozilla.com', contact: '+91 12345 67890', avatar: 'path_to_avatar' },
    { id: 10, name: 'Kate Morrison', date: '26-Aug-2023', email: 'KateMorrison@zerozilla.com', contact: '+91 12345 67890', avatar: 'path_to_avatar' },
    { id: 11, name: 'Chris Blue', date: '26-Aug-2023', email: 'ChrisBlue@zerozilla.com', contact: '+91 12345 67890', avatar: 'path_to_avatar' },
    { id: 12, name: 'Olivia Green', date: '26-Aug-2023', email: 'OliviaGreen@zerozilla.com', contact: '+91 12345 67890', avatar: 'path_to_avatar' },
    { id: 13, name: 'Sam Brown', date: '26-Aug-2023', email: 'SamBrown@zerozilla.com', contact: '+91 12345 67890', avatar: 'path_to_avatar' },
    { id: 14, name: 'Nina White', date: '26-Aug-2023', email: 'NinaWhite@zerozilla.com', contact: '+91 12345 67890', avatar: 'path_to_avatar' },
    { id: 15, name: 'Oliver Black', date: '26-Aug-2023', email: 'OliverBlack@zerozilla.com', contact: '+91 12345 67890', avatar: 'path_to_avatar' },
    { id: 26, name: 'Emma Brown', date: '26-Aug-2023', email: 'EmmaBrown@zerozilla.com', contact: '+91 12345 67890', avatar: 'path_to_avatar' },
    { id: 17, name: 'Liam Gray', date: '26-Aug-2023', email: 'LiamGray@zerozilla.com', contact: '+91 12345 67890', avatar: 'path_to_avatar' },
    { id: 18, name: 'Sophia Violet', date: '26-Aug-2023', email: 'SophiaViolet@zerozilla.com', contact: '+91 12345 67890', avatar: 'path_to_avatar' },
    { id: 19, name: 'Jacob Green', date: '26-Aug-2023', email: 'JacobGreen@zerozilla.com', contact: '+91 12345 67890', avatar: 'path_to_avatar' },
    { id: 20, name: 'Ava Blue', date: '26-Aug-2023', email: 'AvaBlue@zerozilla.com', contact: '+91 12345 67890', avatar: 'path_to_avatar' },
  ];