import { Star, StarOutline } from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { deleteMovie } from "../../services/movieupload";
import toast from "react-hot-toast";
import crossIcon from "../../../src/images/Icons/crossIcon.svg";
import { useNavigate } from "react-router-dom";
import { ButtonGroup } from "react-bootstrap";
import Thankyou from "../../images/Thankyou.png";
import moment from "moment/moment";

const TicketConfirmed = ({
  selectedMovie,
  open,
  ticketConfirmHandler,
  firstShowData,
}) => {
  const navigate = useNavigate();
  const createdAt = moment(firstShowData?.created_at);
  const expiryDate = moment(firstShowData?.expiry_date);

  const differenceInDays = () => {
    if (
      moment(firstShowData?.expiry_date).format("DD-MM-YYYY") ===
      moment(expiryDate).format("DD-MM-YYYY")
    ) {
      return `${1} day `;
    } else if (expiryDate.diff(createdAt, "days") > 0) {
      return `${expiryDate.diff(createdAt, "days")} days. `;
    }
  };
  return (
    <div
      style={{
        display: "flex",
        gap: "1rem",
      }}
    >
      <Dialog
        open={open}
        // onClose={() => {
        //   ticketConfirmHandler();
        // }}
        sx={{
          "& .MuiPaper-root": {
            backgroundColor: "#000",
            color: "#FFF",
            width: "600px",
            padding: {
              xs: "1rem",
              sm: "1rem",
              md: "36px 36px 48px 36px",
              lg: "36px 36px 48px 36px",
            },
            borderRadius: "16px",
          },
        }}
      >
        <DialogTitle
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            position: "relative",
          }}
        >
          <div>
            <Typography
              variant="h6"
              color="inherit"
              style={{
                fontSize: "18px",
                fontWeight: "700",
                lineHeight: "23.44px",
                letterSpacing: "0.01em",
                textAlign: "left",
                width: "100%",
                color: "#FED530",
                marginBottom: "0.5rem",
                fontFamily: "DM Sans",
              }}
            >
              {"How do you rate the movie?"}
            </Typography>
            <Typography
              variant="h6"
              color="inherit"
              style={{
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "20.83px",
                textAlign: "left",
                width: "486px",
                color: "#E6E6E6",
                fontFamily: "DM Sans",
              }}
            >
              Corem ipsum dolor sit amet, consectetur elit.{" "}
            </Typography>
          </div>
          <div>
            <Button
              sx={{
                color: "white",
                position: "absolute",
                top: "-0",
                right: "-10px",
                display:{
                  xs: "none",
                  sm: "none",
                  md: "block",
                  lg: "block",
                }
              }}
              onClick={() => {
                navigate("/moredetails/" + selectedMovie);
                ticketConfirmHandler();
              }}
            >
              <img src={crossIcon} alt="crossIcon" height={44} />
            </Button>
          </div>
        </DialogTitle>
        <DialogContent>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "2rem",
              textAlign: "start",
            }}
          >
            <div>
              <img
                src={Thankyou}
                alt="Thankyou"
                style={{
                  objectFit: "none",
                  height: "320px",
                  width: "100%",
                }}
              />
              <Typography
                style={{
                  fontSize: "18px",
                  fontWeight: "700",
                  lineHeight: "23.44px",
                  letterSpacing: "0.01em",
                  textAlign: "left",
                  width: "100%",
                  marginBottom: "0.5rem",
                  fontFamily: "DM Sans",
                }}
              >
                {`Watch this movie within  `}
                {differenceInDays()}

                {`from ${moment(firstShowData?.created_at).format(
                  "MMM Do"
                )} to  ${moment(firstShowData?.expiry_date).format("MMM Do")} `}
              </Typography>
              <Typography>
                Thank you for buying ticket to this movie , you can watch this
                movie within one month and once you start the movie you should
                watch within 48 hours after expiry you should buy again , you
                can only view and you cannot download and you cannot record or
                share {" "}
              </Typography>
            </div>
            {""}
            <div
              style={{
                display: "flex",
                gap: "1rem",
              }}
            >
              <Button
                sx={{
                  width: "176px",
                  height: "40px",
                  padding: "10px 20px 10px 16px",
                  borderRadius: "34px",
                  border: "1px solid gray",
                  background: "#fed530",
                  fontSize: "14px",
                  lineHeight: "24px",
                  textTransform: "capitalize",
                  fontWeight: "500",
                  color: "#000",
                  "&:hover": {
                    border: "1px solid #ray",
                    background: "#fed530",

                    color: "#000",
                  },
                }}
                variant="contained"
                onClick={() => navigate("/movie/streaming/" + selectedMovie)}
              >
                Watch Movie Now
              </Button>
            </div>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default TicketConfirmed;
