import React from "react";
import "./noimage.scss";
import { Typography } from "@mui/material";

const NodataImage = ({ data }) => {
  return (
    <div className="noImage">
      <Typography className="noimageText">{data}</Typography>
    </div>
  );
};

export default NodataImage;
