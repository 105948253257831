import React from 'react'
import { Grid, Typography } from "@mui/material";
import ScreenImg2 from "../../images/screen2.png";
import "../../scss/Contact.scss";

const CinetSays = () => {
    return (
        <Grid container className="screen-body pt-lg-5">
            <Grid container>
                <Grid
                    item
                    xs={12}
                    md={6}
                    sx={{
                        py: { xs: "1rem", md: 7 },
                        px: { xs: "1rem", md: 10 },
                    }}
                >
                    <div className='mt-5 pt-3'>
                        <Typography variant="para" className="screenPara1">
                            CINET
                        </Typography>
                        <br />
                        <Typography variant="para" className="screenPara2">
                            SAYS
                        </Typography>
                        <br />
                        <Typography variant="para" className="screenPara3">
                            Don't worry, we're here for you. Nowadays, movies aren't just for
                            cinemas; you can watch them on smartphones, TVs, laptops, and
                            tablets. CINET streams new films alongside their theater release, so
                            film lovers worldwide can enjoy them. Our goal is to reach as many
                            viewers as possible on the first day
                        </Typography>
                    </div>

                </Grid>
                <Grid
                    item
                    xs={12}
                    md={6}
                    sx={6}
                >
                    <div>
                        <img
                            src={ScreenImg2}
                            alt="Contact"
                        />
                    </div>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default CinetSays