import React from 'react'
import Screen1 from './Screen1'
import ViewersSays from './ViewersSays'
import CinetSays from './CinetSays'

const ScreenHome = () => {
    return (
        <>
            <Screen1 />
            <ViewersSays />
            <CinetSays />
        </>
    )
}

export default ScreenHome