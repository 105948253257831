export const showPlayButton = (data) => {
  if (data?.movie_plan === "first_show" && data?.is_buied === true) {
    return true;
  } else if (
    data?.movie_plan === "subscribed" &&
    data?.is_subscribed === true
  ) {
    return true;
  } else {
    return false;
  }
};
