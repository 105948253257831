import React, { useEffect, useState } from "react";
import "../../scss/CrossfadeCarousel.scss";
   
import { getWishlist, toggleWishlist } from "../../services/home";
import Spinner from "react-bootstrap/Spinner";
import { useLocation } from "react-router-dom";
import MoviesConstruction from "../UnderConstruction/MoviesConstruction";
import { Toaster, toast } from "react-hot-toast";
import { Grid, Tooltip, Typography } from "@mui/material";
import "../../scss/WatchListMovies.scss"; // Import the new CSS file
import lack from "../../images/moredetails/Lock.svg";
import save from "../../images/moredetails/Save.svg";
import WishedList from "../../images/moredetails/WishedList.svg";
import info from "../../images/moredetails/Info.svg";
import share from "../../images/moredetails/Share.svg";
import clock from "../../images/moredetails/clock.svg";
import play from "../../images/moredetails/play.svg";
import { FaStar } from "react-icons/fa";
import { showPlayButton } from "../HomePage/showthePlayButton";
import { useNavigate } from "react-router-dom";
import SharePopUpBox from "../../common/sharePopUp/SharePopUp";

export default function WatchListMovies() {
  const navigate = useNavigate();
  const location = useLocation();
  const { languageId } = location.state || {};
  const [value, setValue] = useState({});
  const [loading, setLoading] = useState(true);
  const isAuthenticated = localStorage.getItem("isAuthenticated");

  useEffect(() => {
    WatchListApi();
  }, []);

  const handleSeeMore = (id) => {
    navigate(`/moredetails/${id}`, { state: { id } });
  };

  const WatchListFun = async (id) => {
    const payload = {
      movie_id: id,
    };
    if (isAuthenticated === "true") {
      try {
        const response = await toggleWishlist(payload);
        if (response?.data?.status) {
          toast.success(response?.data?.message);
          WatchListApi();
        }
      } catch (error) {
        toast.error("Something went wrong");
      }
    } else {
      toast.error("Please login to add to watchlist");
    }
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  const search = "";
  const type = "";
  const id = languageId || "";
  const WatchListApi = async () => {
    try {
      const response = await getWishlist(search, type, id);

      if (response && response.data) {
        setValue(response?.data?.data?.wish_list);
      }
    } catch (error) {
      toast.error("Something went wrong");
    }
    setLoading(false);
  };

  const isAllEmpty = (obj) => {
    return Object.values(obj).every(
      (arr) => Array.isArray(arr) && arr.length === 0
    );
  };

  const convertSecondsToHrMin = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    return hours > 0 ? `${hours} Hr ${minutes} Min` : `${minutes} Min`;
  };
  const [showFullDescription, setShowFullDescription] = useState({});

  const [shareDetials, setShareDetials] = useState(false);
  const [shareMovie, setShareMovie] = useState("");

  const OpenSharePopUp = (item) => {
    setShareMovie(item?.movie_id);
    setShareDetials(true);
  };

  const CloseSharePopUp = () => {
    setShareDetials(false);
  };

  const toggleDescription = (index) => {
    setShowFullDescription((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  return (
    <div className="mainback">
      <Toaster />

      {loading ? (
        <div className="loader">
          <Spinner animation="border" variant="warning" />
        </div>
      ) : isAllEmpty(value) ? (
        <MoviesConstruction />
      ) : (
        <Grid container sx={{ p: 4 }}>
          {value?.map((item, idx) => {
            return (
              <Grid item xs={12} sm={6} md={4} lg={2.4} sx={{ mb: 2, px: 2 }} key={idx}>
                <div className="image-container-watchlist-page">
                  <img
                    src={item?.Movie?.thumbnail}
                    alt="Watch History"
                    style={{ height: "400px", width: "100%", borderRadius: "0.25rem" }}
                    // className="image-watchlist-page"
                  />
                  <div className="overlay-watchlist-page">
                    <div className="content">
                      <div className="info">
                        <div
                          className="UA-c"
                          style={{ marginBottom: "0.5rem" }}
                        >
                          {item?.Movie?.Certificate?.name}
                        </div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <img
                            src={clock}
                            alt="Clock Icon"
                            style={{
                              height: "20px",
                              width: "20px",
                              marginRight: "5px",
                            }}
                          />
                          <span className="duration">
                            {item?.Movie?.duration
                              ? convertSecondsToHrMin(item?.Movie?.duration)
                              : ""}
                          </span>
                          <span className="stars">
                            <FaStar
                              color="gold"
                              style={{ marginBottom: "5px", marginLeft: "5px" }}
                            />{" "}
                            {item?.Movie?.rating}
                          </span>
                        </div>
                      </div>
                      <Typography variant="h6">{item?.Movie?.title}</Typography>

                      <Typography
                        className="description"
                        style={{ marginBottom: "10px" }}
                      >
                        <div
                          className={`text ${
                            showFullDescription[idx] ? "full" : "truncated"
                          }`}
                        >
                          {item?.Movie?.description}
                        </div>
                        {item?.Movie?.description.split("\n")?.length > 3 &&
                          !showFullDescription[idx] && (
                            <span
                              className="see-more"
                              onClick={() => toggleDescription(idx)}
                            >
                              ....
                            </span>
                          )}
                      </Typography>
                      <div className="icons">
                        {!showPlayButton(item?.Movie) ? (
                          <Tooltip title="Pay/Rent Now" placement="top">
                            <img
                              src={lack}
                              alt="Lock Icon"
                              style={{
                                height: "40px",
                                width: "40px",
                                cursor: "pointer",
                              }}
                              onClick={() =>
                                isAuthenticated === "true"
                                  ? handleSeeMore(item?.movie_id)
                                  : navigate("/login")
                              }
                            />
                          </Tooltip>
                        ) : (
                          <Tooltip title="Watch Now" placement="top">
                            <img
                              src={play}
                              alt="Lock Icon"
                              style={{
                                height: "40px",
                                width: "40px",
                                cursor: "pointer",
                              }}
                              onClick={() =>
                                isAuthenticated === "true"
                                  ? handleSeeMore(item?.movie_id)
                                  : navigate("/login")
                              }
                            />
                          </Tooltip>
                        )}
                        <Tooltip title={`${"My Watch List"} `} placement="top">
                          <img
                            src={WishedList}
                            alt="Save Icon"
                            style={{
                              height: "40px",
                              width: "40px",
                              cursor: "pointer",
                            }}
                            onClick={() => WatchListFun(item?.movie_id)}
                          />
                        </Tooltip>
                        <Tooltip title="Share" placement="top">
                          <img
                            src={share}
                            alt="Share Icon"
                            style={{
                              height: "40px",
                              width: "40px",
                              cursor: "pointer",
                            }}
                            onClick={() => OpenSharePopUp(item)}
                          />
                        </Tooltip>

                        <div className="icon-right">
                          <Tooltip title="More Info" placement="top">
                            <img
                              src={info}
                              alt="Info Icon"
                              style={{
                                height: "40px",
                                width: "40px",
                                cursor: "pointer",
                              }}
                              onClick={() => handleSeeMore(item?.movie_id)}
                            />
                          </Tooltip>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Grid>
            );
          })}
          <SharePopUpBox
            open={shareDetials}
            onClose={CloseSharePopUp}
            data={shareMovie}
          ></SharePopUpBox>
        </Grid>
      )}
    </div>
  );
}
