import React, { useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import { uploadImageApi } from "../../services/movieupload";
import { signup } from "../../services/auth";
import { getprofileDetailS } from "../../services/account";
import { useDispatch } from "react-redux";
import { Backdrop, CircularProgress } from "@mui/material";
import "../../scss/Producerdetails.scss";

const ProducerDetails = () => {
  const location = useLocation();
  const { mobileNumber, data } = location.state || {};
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [formState, setFormState] = useState({
    role: "producer",
    pan_number: "",
    id_proof_url: "",
    gst_number: "",
    gst_certificate_url: "",
    cancelled_cheque_url: "",
    bank_account_number: "",
    bank_name: "",
    account_holder_name: "",
    ifsc_code: "",
    terms_and_condition: false,
  });
  const [files, setfiles] = useState(null);

  const [errors, setErrors] = useState({});
  const validateField = (name, value) => {
    const newErrors = { ...errors };
    switch (name) {
      case "production_house_name":
        newErrors.production_house_name = !value?.trim()
          ? "Production House Name is required."
          : "";
        break;
      case "gst_number":
        newErrors.gst_number = !value?.trim()
          ? "GST Number is required."
          : value.length !== 15
          ? "GST Number must be exactly 15 characters."
          : !/^[A-Z0-9]{15}$/.test(value)
          ? "Invalid GST Number format."
          : "";
        break;
      case "pan_number":
        newErrors.pan_number = !value?.trim()
          ? "PAN Number is required."
          : value.length !== 10
          ? "PAN Number must be exactly 10 characters."
          : !/[A-Z]{5}[0-9]{4}[A-Z]{1}/.test(value)
          ? "Invalid PAN Number format."
          : "";
        break;
      case "bank_account_number":
        newErrors.bank_account_number = !value?.trim()
          ? "Bank Account Number is required."
          : !/^\d{9,18}$/.test(value)
          ? "Bank Account Number must be between 9 to 18 digits."
          : "";
        break;
      case "ifsc_code":
        newErrors.ifsc_code = !value?.trim()
          ? "IFSC Code is required."
          : value.length !== 11
          ? "IFSC Code must be exactly 11 characters."
          : !/^[A-Z]{4}0[A-Z0-9]{6}$/.test(value)
          ? "Invalid IFSC Code format."
          : "";
        break;
      case "account_holder_name":
        newErrors.account_holder_name = !value?.trim()
          ? "Account Holder's Name is required."
          : "";
        break;
      case "id_proof_url":
      case "cancelled_cheque_url":
      case "gst_certificate_url":
        newErrors[name] = !value
          ? `${name.replace("_", " ").replace("url", "")} is required.`
          : "";
        break;
      case "terms_and_condition":
        newErrors[name] = !value
          ? `Terms and conditions must be accepted.`
          : "";
        break;

      default:
        break;
    }

    setErrors(newErrors);
  };

  const handleChange = (e) => {
    const { name, value, type, files, checked } = e.target;
    const newValue = type === "checkbox" ? checked : files ? files[0] : value;
    setFormState((prevState) => {
      const updatedState = { ...prevState, [name]: newValue };
      validateField(name, newValue);
      return updatedState;
    });
  };

  const validateForm = () => {
    const newErrors = {};
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleContinue = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      if (data) {
        setLoading(true);
        try {
          const accessToken = localStorage.getItem("token");
          const payload = {
            name: data.user.name,
            email: data.user.email,
            mobile: mobileNumber || "",
            profile_pic: "",
            google_id: data.user.google_id,
            doc_url: "",
            access_token: accessToken,
            ...formState,
          };
          const response = await signup(payload);
          if (response && response.data) {
            if (response.data?.data?.user) {
              const res = dispatch(getprofileDetailS());
              console.log(res);
              localStorage.setItem(
                "user_name",
                response.data?.data?.user?.name
              );
              localStorage.setItem("role", response.data?.data?.user?.role);
              localStorage.setItem("token", response.data?.data?.access_token);
              localStorage.setItem("isAuthenticated", true);
              localStorage.setItem(
                "userData",
                JSON.stringify(response?.data?.data?.user)
              );
            } else {
              localStorage.setItem(
                "user_name",
                response.data?.data?.producer?.name
              );
              localStorage.setItem("role", response.data?.data?.user?.role);
            }
            navigate("/welcome");
          } else {
            throw new Error("Failed to submit personal info");
          }
        } catch (err) {
          console.error("Error submitting personal info:", err);
          toast.error("Error submitting personal info. Please try again.");
        } finally {
          setLoading(false);
        }
      }
    }
  };

  const handleFileChange = async (event) => {
    const { name } = event.target;
    const file = event.target.files[0];
    const allowedTypes = ["image/png", "image/jpeg", "application/pdf"];
    if (!allowedTypes.includes(file.type)) {
      toast.error(
        "Invalid file type. Only .png, .jpg, .jpeg, and .pdf are allowed."
      );
      return;
    }
    setfiles({
      ...files,
      [name]: file,
    });
    setLoading(true);
    if (file) {
      try {
        const formData = new FormData();
        formData.append("file", file);
        const response = await uploadImageApi(formData);
        if (response && response.data) {
          setFormState((prevState) => ({
            ...prevState,
            [name]: response.data.data?.filePath,
          }));
          toast.success("Image uploaded successfully.");
        } else {
          toast.error("Failed to upload image. Please try again.");
        }
      } catch (error) {
        toast.error("An error occurred while uploading the image.");
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <div className="full-page-background-producer">
      <div className="container">
        <Form onSubmit={handleContinue}>
          <Row
            style={{
              marginLeft: "1rem",
            }}
          >
            <Col lg={4} md={6} sm={12}>
              <Form.Label className="my-2">Production House</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Production House Name"
                name="production_house_name"
                onChange={handleChange}
                className="form-control-1"
                isInvalid={!!errors.production_house_name}
                value={formState.production_house_name || ""}
              />
              <Form.Control.Feedback type="invalid">
                {errors.production_house_name}
              </Form.Control.Feedback>
            </Col>
            <Col lg={4} md={6} sm={12}>
              <Form.Label className="my-2">GST Number</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter GST Number"
                name="gst_number"
                onChange={handleChange}
                className="form-control-1"
                isInvalid={!!errors.gst_number}
                value={formState.gst_number || ""}
              />
              <Form.Control.Feedback type="invalid">
                {errors.gst_number}
              </Form.Control.Feedback>
            </Col>
            <Col lg={4} md={6} sm={12}>
              <Form.Label className="my-2">Pan Card</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter PAN Number"
                name="pan_number"
                onChange={handleChange}
                className="form-control-1"
                isInvalid={!!errors.pan_number}
                value={formState.pan_number || ""}
              />
              <Form.Control.Feedback type="invalid">
                {errors.pan_number}
              </Form.Control.Feedback>
            </Col>
            <Col lg={4} md={6} sm={12}>
              <Form.Label className="my-2">
                ID Proof
                <span>(500KB)</span>
              </Form.Label>
              <div
                className="form-control-1 p-0"
                style={{
                  display: "flex",
                  justifyContent: "end",
                  padding: "1rem",
                }}
              >
                <Form.Control
                  type="file"
                  name="id_proof_url"
                  id="file-upload"
                  className="file-input"
                  onChange={handleFileChange}
                  readOnly
                  isInvalid={!!errors.id_proof_url}
                  style={{
                    padding: "1rem",
                  }}
                  accept=".jpg,.jpeg,.png,.pdf"
                />
                <Button
                  style={{
                    background: "#fed530",
                    border: "1px solid #fed530",
                    color: "black",
                    borderRadius:"0px 8px 8px 0px"
                  }}
                  variant="primary"
                  onClick={() => document.getElementById("file-upload").click()}
                  className="input-group-append"
                >
                  Upload
                </Button>
              </div>
            </Col>
            <Col lg={4} md={6} sm={12}>
              <Form.Label className="my-2">
                Cbf Certificate
                <span> (500KB)</span>
              </Form.Label>
              <div
                className="form-control-1 p-0"
                style={{
                  display: "flex",
                  justifyContent: "end",
                  padding: "1rem",
                }}
              >
                <Form.Control
                  type="file"
                  name="cbfc_certificate"
                  id="file-upload"
                  className="file-input"
                  onChange={handleFileChange}
                  readOnly
                  isInvalid={!!errors.id_proof_url}
                  style={{
                    padding: "1rem",
                  }}
                  accept=".jpg,.jpeg,.png,.pdf"
                />
                <Button
                  style={{
                    background: "#fed530",
                    border: "1px solid #fed530",
                    color: "black",
                    borderRadius:"0px 8px 8px 0px"
                  }}
                  variant="primary"
                  onClick={() => document.getElementById("file-upload").click()}
                  className="input-group-append"
                >
                  Upload
                </Button>
              </div>
            </Col>
            <Col lg={4} md={6} sm={12}>
              <Form.Label className="my-2">
                Cancelled Cheque <span>(500KB)</span>
              </Form.Label>
              <div
                // className="input-group"
                className="form-control-1 p-0"
                style={{
                  display: "flex",
                  justifyContent: "end",
                  padding: "1rem",
                }}
              >
                <Form.Control
                  type="file"
                  name="cancelled_cheque_url"
                  onChange={handleFileChange}
                  isInvalid={!!errors.cancelled_cheque_url}
                  style={{
                    padding: "1rem",
                  }}
                  accept=".jpg,.jpeg,.png,.pdf"
                  readOnly
                />
                <Button
                  style={{
                    background: "#fed530",
                    border: "1px solid #fed530",
                    color: "black",
                    borderRadius:"0px 8px 8px 0px"
                  }}
                  className="input-group-append"
                  variant="primary"
                  // accept="image/jpeg,image/gif,image/png,application/pdf,image/x-eps"
                  onClick={() =>
                    document
                      .querySelector('input[name="cancelled_cheque_url"]')
                      .click()
                  }
                >
                  Upload
                </Button>
              </div>
            </Col>
            <Col lg={4} md={6} sm={12}>
              <Form.Label className="my-2">
                GST Certificate
                <span>(500KB)</span>
              </Form.Label>
              <div
                // className="input-group"
                className="form-control-1 p-0"
                style={{
                  display: "flex",
                  justifyContent: "end",
                  padding: "1rem",
                }}
              >
                <Form.Control
                  type="file"
                  name="gst_certificate_url"
                  onChange={handleFileChange}
                  isInvalid={!!errors.gst_certificate_url}
                  style={{
                    padding: "1rem",
                  }}
                  readOnly
                  accept=".jpg,.jpeg,.png,.pdf"
                />
                <Button
                  style={{
                    background: "#fed530",
                    border: "1px solid #fed530",
                    color: "black",
                    borderRadius:"0px 8px 8px 0px"
                  }}
                  className="input-group-append"
                  variant="primary"
                  onClick={() =>
                    document
                      .querySelector('input[name="gst_certificate_url"]')
                      .click()
                  }
                >
                  Upload
                </Button>
              </div>
            </Col>
            <Col lg={4} md={6} sm={12}>
              <Form.Label className="my-2">Bank Account Number</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Bank Account Number"
                name="bank_account_number"
                onChange={handleChange}
                className="form-control-1"
                isInvalid={!!errors.bank_account_number}
                value={formState.bank_account_number || ""}
              />
              <Form.Control.Feedback type="invalid">
                {errors.bank_account_number}
              </Form.Control.Feedback>
            </Col>
            <Col lg={4} md={6} sm={12}>
              <Form.Label className="my-2">Bank Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Bank Name"
                name="bank_name"
                onChange={handleChange}
                className="form-control-1"
                isInvalid={!!errors.bank_name}
                value={formState.bank_name || ""}
              />
              <Form.Control.Feedback type="invalid">
                {errors.bank_name}
              </Form.Control.Feedback>
            </Col>
            <Col lg={4} md={6} sm={12}>
              <Form.Label className="my-2">IFSC Code</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter IFSC Code"
                name="ifsc_code"
                onChange={handleChange}
                className="form-control-1"
                isInvalid={!!errors.ifsc_code}
                value={formState.ifsc_code || ""}
              />
              <Form.Control.Feedback type="invalid">
                {errors.ifsc_code}
              </Form.Control.Feedback>
            </Col>
            <Col lg={4} md={6} sm={12}>
              <Form.Label className="my-2">Account Holder's Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Account Holder's Name"
                name="account_holder_name"
                onChange={handleChange}
                className="form-control-1"
                isInvalid={!!errors.account_holder_name}
                value={formState.account_holder_name || ""}
              />
              <Form.Control.Feedback type="invalid">
                {errors.account_holder_name}
              </Form.Control.Feedback>
            </Col>
            <Col lg={12} md={12} sm={12} className="my-1">
              <Form.Check
                type="checkbox"
                label="I confirm the terms and conditions"
                name="terms_and_condition"
                onChange={handleChange}
                className="form-check-1"
                isInvalid={!!errors.terms_and_condition}
                checked={formState.terms_and_condition || false}
              />
              <Form.Control.Feedback type="invalid">
                {errors.terms_and_condition}
              </Form.Control.Feedback>
            </Col>
          </Row>
          <Button
            type="submit"
            variant="primary"
            className="mt-3"
            style={{
              background: "#fed530",
              border: "1px solid #fed530",
              color: "black",
              marginLeft: "2rem",
            }}
          >
            Submit
          </Button>
        </Form>
      </div>
      <Backdrop
        open={loading}
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};

export default ProducerDetails;
