// src/components/WatchHistory.js
import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import "../../scss/WatchHistory.scss"; // Import the stylesheet

import { getWathHistory } from "../../services/account";
import NodataImage from "./NodataImage";

const WatchHistory = () => {
  const [watchHistory, setWatchHistoryList] = useState([]);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await getWathHistory();
        if (response?.data?.status) {
          setWatchHistoryList(response?.data?.data?.watch_list);
        }
      } catch (error) {
        console.error("Error fetching watch history:", error);
        // Handle the error appropriately, e.g., show a notification to the user
      }
    }

    fetchData();
  }, []);
  return (
    <Box className="watch-history-container">
      <Box className="WatchHistory-heading">
        <Typography variant="h5">Watch History</Typography>
        <Typography>Your viewing activity history</Typography>
      </Box>
      <Box className="image-row">
        {watchHistory?.length > 0 ? (
          watchHistory?.map((Movie, index) => {
            return (
              <img
                key={index}
                src={Movie?.Movie?.thumbnail}
                alt="Watch History"
                className="watch-history-image"
              />
            );
          })
        ) : (
          <NodataImage data={'No Watch History Found'}/>
        )}
      </Box>
    </Box>
  );
};

export default WatchHistory;
