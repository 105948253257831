import React, { useState } from "react";
import { useDropzone } from "react-dropzone";
import { uploadVideoApi } from "../../services/movieupload";
import CloudUploadIcon from "../../images/UploadMoviePage/Featuredicon.svg";
import { Typography, Card, Box } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Toaster, toast } from "react-hot-toast";
import Spinner from "react-bootstrap/Spinner";

const VideoUpload = ({ onUpload, uploadedUrl }) => {
  const [loading, setLoading] = useState(false);
  const [videoSrc, setVideoSrc] = useState(uploadedUrl);

  const onDrop = (acceptedFiles) => {
    const file = acceptedFiles[0];
    console.log(file);

    // if (file.type !== "video/") {
    //   // toast.error("Unsupported file type.");
    //   return;
    // }

    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setVideoSrc(reader.result);
      handleVideoUpload(file);
    };
  };

  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop,
    noClick: true,
    // accept: {
    //   "video/mp4": [],
    //   "video/mkv": [],
    //   "video/mov": [],
    // },
  });

  const handleVideoUpload = async (file) => {
    console.log(file);
    setLoading(true);

    try {
      const formData = new FormData();
      formData.append("file", file);
      const uploadResponse = await uploadVideoApi(formData);
      if (uploadResponse?.data?.status) {
        if (onUpload) {
          onUpload(uploadResponse.data.data.vimeo_video_id);
        }
        toast.success("Video uploaded successfully.");
      } else {
        toast.error("Failed to upload video. Please try again.");
      }
    } catch (error) {
      console.error("Error uploading video:", error);
      toast.error("An error occurred while uploading the video.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <Toaster />
      {!uploadedUrl ? (
        <Card
          className="upload-card"
          sx={{ backgroundColor: "#000" }}
          {...getRootProps()}
        >
          <input {...getInputProps()} />
          <div style={{ textAlign: "center" }}>
            {loading ? (
              <Spinner animation="border" role="status"></Spinner>
            ) : (
              <>
                <img
                  src={CloudUploadIcon}
                  alt=""
                  onClick={open}
                  style={{ height: "60px", width: "60px" }}
                />
                <Typography
                  variant="body1"
                  component="p"
                  className="upload-card-text"
                  onClick={open}
                >
                  Select file to upload
                </Typography>
                <Typography
                  variant="body2"
                  component="p"
                  style={{ color: "white" }}
                  onClick={open}
                >
                  or drag and drop
                </Typography>
              </>
            )}
          </div>
        </Card>
      ) : (
        <Card
          className="upload-card"
          sx={{ backgroundColor: "#000" }}
          {...getRootProps()}
        >
          <input {...getInputProps()} />
          <div style={{ textAlign: "center" }}>
            {loading ? (
              <Spinner animation="border" role="status"></Spinner>
            ) : (
              <>
                <img
                  src={CloudUploadIcon}
                  alt=""
                  onClick={open}
                  style={{ height: "60px", width: "60px" }}
                />
                <Box display="flex" alignItems="center" justifyContent="center">
                  <Typography
                    variant="body1"
                    component="p"
                    className="upload-card-text"
                    onClick={open}
                  >
                    Uploaded Successfully
                  </Typography>
                  <CheckCircleIcon sx={{ color: "green", marginLeft: "8px" }} />
                </Box>
                <Typography
                  variant="body2"
                  component="p"
                  style={{ color: "white" }}
                  onClick={open}
                >
                  Change Video
                </Typography>
              </>
            )}
          </div>
        </Card>
      )}
    </div>
  );
};

export default VideoUpload;
