// src/Layout.js
import React from "react";
import { useLocation } from "react-router-dom";
import Navbar from "./common/navbar/navbar";
import Navbarforlogin from "./common/navbar/Navbarforlogin";
import Footer from "./common/footer/Footer";
import "../src/common/footer/footer.css"
const Layout = ({ children }) => {
  const location = useLocation();
  const navbarHiddenPaths = [
    "/login",
    "/welcome",
    "/unauthorized",
    "/otp",
    "/account",
  ];

  const footerVisiblePaths = [
    "/mainpage",
    "/contact-us",
    "/language",
    "/moredetails",
    "/movies/first-show",
    "/movies/subscribe",
    "/movieUpload",
    "/privacy-policy",
    "/terms-condition",
  ];
  const showNavbar = !navbarHiddenPaths.some((path) =>
    location.pathname.startsWith(path)
  );
  const showFooter = footerVisiblePaths.some((path) =>
    location.pathname.startsWith(path)
  );

  return (
    <>
      {showNavbar ? <Navbar /> : <Navbarforlogin />}
      {children}
      {showFooter && <Footer />}
    </>
  );
};

export default Layout;
