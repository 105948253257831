// src/components/Transactions.jsx
import React, { useEffect, useState } from "react";
import { Box, Typography, Grid } from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import "../../scss/Transactions.scss";
import { getTransactionHistory } from "../../services/account";
import NodataImage from "./NodataImage";
import subscribeIcon from "../../images/AccountPage/subscribe_icon.svg";
import moment from "moment/moment";
import { useNavigate } from "react-router-dom";

const Transactions = () => {
  const [TransactionHistory, setgetTransactionHistory] = useState([]);
  const navigate = useNavigate();

  const handleNavigate = (movie) => {
    if (movie?.Subscription) {
      navigate(`/movies/subscribe`);
    } else {
      navigate(`/moredetails/${movie?.Movie?.id}`);
    }
  };
  useEffect(() => {
    async function fetchData() {
      try {
        const response = await getTransactionHistory();
        if (response?.data?.status) {
          setgetTransactionHistory(response?.data?.data?.transactions);
        }
      } catch (error) {
        console.error("Error fetching transaction history:", error);
        // Handle the error appropriately, e.g., show a notification to the user
      }
    }

    fetchData();
  }, []);

  return (
    <Box className="transactions">
      <Box className="Transactions-heading">
        <Typography variant="h5" component="div" gutterBottom>
          Transaction History
        </Typography>
        <Typography variant="subtitle1" component="div" gutterBottom>
          Your viewing activity history
        </Typography>
      </Box>
      {TransactionHistory?.length > 0 ? (
        TransactionHistory.map((transaction, index) => (
          <Box
            key={index}
            className="transaction-card"
            onClick={() => {
              handleNavigate(transaction);
            }}
          >
            <img
              src={transaction.Movie?.thumbnail || subscribeIcon}
              alt={transaction.title}
              className={`${
                transaction.Movie?.thumbnail
                  ? "transaction-image-rental"
                  : "transaction-image-subscribtion"
              }`}
            />
            <Box className="transaction-info">
              <Typography variant="subtitle1" className="transaction-title">
                {transaction?.Movie?.title}
              </Typography>
              <Typography variant="subtitle1">
                INR {transaction?.price}
                {transaction?.Subscription !== null &&
                  `  /  ${transaction?.Subscription?.title}`}
              </Typography>
              <Typography variant="body2" className="rent-label">
                {transaction?.type
                  ?.replace(/_/g, " ")
                  ?.replace(/\b\w/g, (char) => char?.toUpperCase())}
              </Typography>
              <Typography variant="subtitle1" className="transaction-title">
                {transaction.title}
              </Typography>
              <Typography variant="body2" className="transaction-date">
                Transaction date:{" "}
                {moment(transaction.createdAt)?.format("D MMM YYYY")}
              </Typography>
            </Box>
            <ArrowForwardIosIcon className="transaction-arrow" />
          </Box>
        ))
      ) : (
        <NodataImage data={"No Transaction History Found"} />
      )}
    </Box>
  );
};

export default Transactions;
