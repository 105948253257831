import { ApiService } from "./ApiService/ApiService";
import { urlConfig } from "../config/urlConfig";
import { userDeatils } from "../store/sample/action";

export const updateProfile = (payload) => {
  return ApiService.post(urlConfig.account.updateProfile, payload);
};
export const getprofileDetailS = (payload) => {
  return async (dispatch) => {
    try {
      const response = await ApiService.get(
        urlConfig.account.getprofileDetailS,
        payload
      );
      dispatch(userDeatils(response.data?.data?.user));
      return response;
    } catch (error) {
      // toast.error(error?.response?.data.message);
      console.log(error)
    }
  };
};
export const getWathHistory = () => {
  const url = `${urlConfig.account.getWathHistory}`;
  return ApiService.get(url);
};
export const getTransactionHistory = (payload) => {
  const url = `${urlConfig.account.getTransactionHistory}`;
  return ApiService.get(url);
};
export const getUploadedMovies = () => {
  const url = `${urlConfig.account.getUploadedMovies}`;
  return ApiService.get(url);
};
