import React, { useState } from "react";
import { uploadVideoApi } from "../../services/movieupload";
import { Typography, Box } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Toaster, toast } from "react-hot-toast";
import Spinner from "react-bootstrap/Spinner";

const SongVideoUpload = ({ onUpload, uploadedUrl, index }) => {
  const [loading, setLoading] = useState(false);
  const [fileName, setFileName] = useState("");

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    setFileName(file.name);

    // Check if the file is a video
    if (!file.type.startsWith("video/")) {
      toast.error("Unsupported file type. Please upload a video.");
      return;
    }

    // Check if the file size exceeds 100MB
    const maxSizeInBytes = 1024 * 1024 * 1024 * 5;
    if (file.size > maxSizeInBytes) {
      toast.error("File size exceeds the 5 GB limit.");
      return;
    }

    handleVideoUpload(file);
  };

  const handleVideoUpload = async (file) => {
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append("file", file);
      const uploadResponse = await uploadVideoApi(formData);
      if (uploadResponse.status === 200) {
        if (onUpload) {
          onUpload(uploadResponse.data.data.vimeo_video_id);
        }
        toast.success("Video uploaded successfully.");
      } else {
        toast.error("Failed to upload video. Please try again.");
      }
    } catch (error) {
      toast.error("An error occurred while uploading the video.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <Toaster />

      {!uploadedUrl ? (
        <>
          <Typography
            variant="body2"
            component="p"
            style={{ marginBottom: "25px" }}
          >
            Upload Video File
          </Typography>
          <input
            type="file"
            style={{ display: "none" }}
            id={`file-input-${index}`}
            onChange={handleFileChange}
          />
          <label
            htmlFor={`file-input-${index}`}
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              cursor: "pointer",
            }}
          >
            <span
              style={{
                flexGrow: 1,
                backgroundColor: "#161616",
                padding: "15px 10px",
              }}
            >
              {fileName || "Choose file..."}
            </span>
            <span
              style={{
                backgroundColor: "#323232",
                color: "white",
                padding: "15px 10px",
              }}
            >
              Browse
            </span>
          </label>
          {loading && (
            <div style={{ textAlign: "center", marginTop: "10px" }}>
              <Spinner animation="border" variant="light" />
            </div>
          )}
        </>
      ) : (
        <>
          <input
            type="file"
            style={{ display: "none" }}
            id={`file-input-change-${index}`}
            onChange={handleFileChange}
          />
          <label
            htmlFor={`file-input-change-${index}`}
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              cursor: "pointer",
            }}
          >
            <span
              style={{
                flexGrow: 1,
                backgroundColor: "#161616",
                padding: "15px 10px",
              }}
            >
              {fileName || "Choose file..."}
            </span>
            <span
              style={{
                backgroundColor: "#323232",
                color: "white",
                padding: "15px 10px",
              }}
            >
              Browse
            </span>
          </label>
          <Typography
            variant="body1"
            component="p"
            className="upload-card-text"
          >
            Uploaded Successfully
          </Typography>
          <CheckCircleIcon sx={{ color: "green", marginLeft: "8px" }} />
          <Typography variant="body2" component="p" style={{ color: "white" }}>
            Change Video
          </Typography>
        </>
      )}
    </div>
  );
};

export default SongVideoUpload;
