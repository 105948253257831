import { ApiService } from "./ApiService/ApiService";
import { urlConfig } from "../config/urlConfig";

export const homelist = (search, type, id) => {
  const url = `${urlConfig.home.homelist}?search=${search}&type=${type}&language_id=${id}`;
  return ApiService.get(url);
};

export const languagelist = (search) => {
  const url = `${urlConfig.language.list}?search=${search}`;
  return ApiService.get(url);
};
