import React from "react";
import UploadedMovies from "./uploadedMovies";

const YourUploads = () => {
  return (
    <div>
      <UploadedMovies />
    </div>
  );
};

export default YourUploads;
