import React, { useState } from "react";
import Typography from "@mui/material/Typography";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import { useNavigate, useLocation } from "react-router-dom";
import "../../scss/AccountPage.scss";
import Strings from "../../common/string";
import people from "../../images/welcome/people.svg";
import film from "../../images/welcome/film.svg";
import { signup } from "../../services/auth";
import { useDispatch } from "react-redux";
import { getprofileDetailS } from "../../services/account";

export default function AccountPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const { mobileNumber, data, login_status } = location.state || {};
  const [selectedCard, setSelectedCard] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const handleBack = () => {
    navigate("/Otp");
  };

  const handleCardClick = (card) => {
    if (card === "user") {
      setSelectedCard(card);
    } else {
      if (data !== undefined) {
        navigate("/producer-details", {
          state: { data: data, login_status: false },
        });
      } else {
        navigate("/info", {
          state: {
            login_status: false,
            selectedCard: card,
            mobileNumber: mobileNumber,
          },
        });
      }
    }
  };

  const handleContinue = async () => {
    if (selectedCard === "user" && selectedCard !== null) {
      if (data) {
        try {
          const accessToken = localStorage.getItem("token");
          const payload = {
            name: data.user.name,
            email: data.user.email,
            mobile: "",
            profile_pic: "",
            role: selectedCard,
            google_id: data.user.google_id,
            doc_url: "",
            access_token: accessToken,
          };
          const response = await signup(payload);
          if (response && response.data) {
            if (response.data?.data?.user) {
              dispatch(getprofileDetailS());
              localStorage.setItem(
                "user_name",
                response.data?.data?.user?.name
              );
              localStorage.setItem("role", response.data?.data?.user?.role);
              localStorage.setItem("token", response.data?.data?.access_token);
            } else {
              localStorage.setItem(
                "user_name",
                response.data?.data?.producer?.name
              );
              localStorage.setItem("role", response.data?.data?.user?.role);
            }
            if (login_status === false && selectedCard === "user") {
              navigate("/language");
            } else if (login_status === false && selectedCard === "producer") {
              navigate("/welcome");
            } else {
              navigate("/info", {
                state: { login_status: false, selectedCard: selectedCard },
              });
            }
          } else {
            throw new Error("Failed to submit personal info");
          }
        } catch (err) {
          setError(err.message);
          console.error("Error submitting personal info:", err);
        } finally {
          setLoading(false);
        }
      } else if (selectedCard && data == null) {
        navigate("/info", {
          state: { mobileNumber: mobileNumber, selectedCard: selectedCard },
        });
      } else {
        alert("Please select an account type");
      }
    }
  };

  return (
    <div className="full-page-background-account">
      <div className="container">
        <div className="background-account">
          <div className="cardvalue-otp">
            <Button
              className="skip-otp"
              variant="secondary"
              onClick={handleBack}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="25"
                viewBox="0 0 25 25"
                fill="none"
              >
                <path
                  d="M15.5 18.5L9.5 12.5L15.5 6.5"
                  stroke="white"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </Button>
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              className="login-Header-otp mb-3"
            >
              {Strings.ACCOUNT_TYPE}
            </Typography>
            <div>
              <div className="">
                <Card
                  onClick={() => handleCardClick("user")}
                  style={{
                    width: "auto",
                    backgroundColor: "#333",
                    color: "#fff",
                    border:
                      selectedCard === "user" ? "2px solid yellow" : "none",
                    cursor: "pointer",
                    height: "auto",
                    padding: "16px",
                    borderRadius: "16px",
                  }}
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <img
                      src={people}
                      alt="img"
                      style={{
                        marginRight: "10px",
                        width: "36px",
                        height: "36px",
                      }}
                    />
                    <div>
                      <Card.Title
                        style={{
                          fontFamily: "DM Sans",
                          fontSize: "18px",
                          fontWeight: 700,
                          color: "#FFF",
                        }}
                      >
                        User
                      </Card.Title>
                    </div>
                  </div>
                </Card>
              </div>
              <Card
                onClick={() => handleCardClick("producer")}
                style={{
                  width: "auto",
                  backgroundColor: "#333",
                  color: "#fff",
                  marginTop: "15px",
                  border:
                    selectedCard === "producer" ? "2px solid yellow" : "none",
                  cursor: "pointer",
                  borderRadius: "16px",
                  height: "auto",
                }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <img
                    src={film}
                    alt="img"
                    style={{
                      marginRight: "10px",
                      width: "36px",
                      height: "36px",
                    }}
                  />
                  <div>
                    <Card.Title
                      style={{
                        fontFamily: "DM Sans",
                        fontSize: "18px",
                        fontWeight: 700,
                        color: "#FFF",
                      }}
                    >
                      Producer/Channel
                    </Card.Title>
                  </div>
                </div>
              </Card>
            </div>
            <Button
              className="Continue-Button-Account mt-4"
              variant="secondary"
              onClick={handleContinue}
            >
              {Strings.CONTINUE_BUTTON}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
