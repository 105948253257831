import React from 'react'
import { Grid, Typography } from "@mui/material";
import ScreenImg1 from "../../images/screen1.png";
import "../../scss/Contact.scss";

const Screen1 = () => {
    return (
        <Grid container className="screen-body">
            <Grid container>
                <Grid
                    item
                    xs={12}
                    md={6}
                    sx={{
                        py: { xs: "1rem", md: 7 },
                        px: { xs: "1rem", md: 10 },
                    }}
                >
                    <div className='mt-5 pt-3'>
                        <Typography variant="para" className="screenPara1">
                            FILMMAKER
                        </Typography>
                        <br />
                        <Typography variant="para" className="screenPara2">
                            SAYS
                        </Typography>
                        <br />
                        <Typography variant="para" className="screenPara3">
                            We create movies with passion and aim to share them with as
                            many viewers as possible. While watching films on the big screen
                            is unparalleled, cinema halls are vanishing in suburban and rural
                            areas, and it's challenging to find theaters in other states and
                            abroad. Additionally, theater audiences are dwindling.
                        </Typography>
                    </div>

                </Grid>
                <Grid
                    item
                    xs={12}
                    md={6}
                    sx={6}
                >
                    <div>
                        <img
                            src={ScreenImg1}
                            alt="Contact"
                        />
                    </div>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default Screen1