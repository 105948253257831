// import React, { useState, useEffect } from "react";

// const Timer = ({ initialTime, onTimeout }) => {
//   const [time, setTime] = useState(initialTime);

//   useEffect(() => {
//     const intervalId = setInterval(() => {
//       setTime((prevTime) => (prevTime > 0 ? prevTime - 1 : 0));
//     }, 1000);

//     return () => clearInterval(intervalId);
//   }, []);

//   useEffect(() => {
//     if (time === 0 && onTimeout) {
//       onTimeout(); // Call the timeout handler when time reaches zero
//     }
//   }, [time, onTimeout]);

//   const formatTime = (seconds) => {
//     const secs = seconds % 60;
//     return `${secs < 10 ? `0${secs}` : secs}`;
//   };

//   return <div className="timer">{formatTime(time)} s</div>;
// };

// export default Timer;


import React, { useEffect, useState, forwardRef, useImperativeHandle } from 'react';

const Timer = forwardRef(({ initialTime, onTimeout }, ref) => {
  const [timeLeft, setTimeLeft] = useState(initialTime);

  useImperativeHandle(ref, () => ({
    startTimer: () => {
      setTimeLeft(initialTime);
    },
  }));

  useEffect(() => {
    if (timeLeft <= 0) {
      onTimeout();
      return;
    }

    const timerId = setInterval(() => {
      setTimeLeft(prevTime => prevTime - 1);
    }, 1000);

    return () => clearInterval(timerId);
  }, [timeLeft, onTimeout]);

  return <div style={{color:"red",display:"flex",justifyContent:"center"}}>{timeLeft}s</div>;
});

export default Timer;


